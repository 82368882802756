export const JobStatus = {
  VALIDATION_STARTED: "validation_started",
  VALIDATION_COMPLETE_SUCESS: "validation_sucess",
  VALIDATION_COMPLETE_FAILED: "validation_falied",
  DISBUSTAL_INITITATED: "disbursal_initiated",
  DISBUSTAL_RUNNING: "disbursal_running",
  DISBUSTAL_COMPLETE: "disbursal_complete",
};

export const safeJsonParse = (str: string, defaultValue = {}) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return defaultValue;
  }
};
