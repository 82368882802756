import { AxiosRequestConfig } from "axios";
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import { clearStorage, getStorage, handleStorage } from "./handleStorage";
import axiosClient from "./request";

export const tokenKey = `${process.env.REACT_APP_NAMESPACE}.token`;
export const dataKey = `${process.env.REACT_APP_NAMESPACE}.token.data`;
export const refreshKey = `${process.env.REACT_APP_NAMESPACE}.token.refresh`;
export const deviceKey = `${process.env.REACT_APP_NAMESPACE}.device.id`;

export interface TokenResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

export interface UserIdentity {
  name: string;
  avatar: string;
}

function setAuthData(data: TokenResponse) {
  const decoded = jwt_decode(data.access_token);
  localStorage.setItem(tokenKey, data.access_token);
  localStorage.setItem(dataKey, JSON.stringify(decoded));
  localStorage.setItem(refreshKey, data.refresh_token);
}

function removeAuthData() {
  clearStorage();
}

const authProvider = {
  login: async ({
    email,
    password,
    deviceType,
    appVersion,
  }: {
    email: string;
    password: string;
    deviceType: string;
    appVersion: string;
  }) => {
    let deviceId = getStorage(deviceKey);
    if (!deviceId) {
      deviceId = uuidv4();
      handleStorage(deviceKey, deviceId);
    }

    const params: AxiosRequestConfig = {
      method: "POST",
      url: "/auth/v2/login/bo",
      data: {
        email,
        password,
        device_id: deviceId,
        device_type: deviceType,
        os: appVersion,
      },
    };

    const response = await axiosClient.request({ ...params });

    if (response.status === 200) {
      setAuthData(response.data);
    }
    return response?.data;
  },

  refreshToken: async (payload: { token: string }) => {
    const params: AxiosRequestConfig = {
      method: "POST",
      url: "/auth/v2/token/refresh",
      data: {
        refresh_token: payload.token,
      },
    };

    const response = await axiosClient.request({ ...params });

    if (response.status === 200) {
      setAuthData(response.data);
    }

    return response?.data;
  },

  logout: () => {
    removeAuthData();
    return Promise.resolve();
  },

  checkError: (error: any) => {
    if (error && error.statusCode === 401) {
      removeAuthData();
      return Promise.reject();
    }

    return Promise.resolve();
  },

  checkAuth: () => {
    try {
      const token = localStorage.getItem(tokenKey);
      const tokenData = localStorage.getItem(dataKey);
      const refreshToken = localStorage.getItem(tokenKey);
      if (token && refreshToken && tokenData) {
        return {
          access_token: token,
          refresh_token: refreshToken,
          ...JSON.parse(tokenData).user,
        };
      }
      return null;
    } catch (error: any) {
      Promise.reject(error);
    }
  },

  getPermissions: () => {
    const authData = localStorage.getItem(dataKey);
    if (authData) {
      const parsedUser = JSON.parse(authData);
      return Promise.resolve([parsedUser.role]);
    }
    return Promise.reject();
  },

  getUserIdentity: () => {
    const authData = localStorage.getItem(dataKey);
    if (authData) {
      const parsedUser = JSON.parse(authData);
      return Promise.resolve(parsedUser.email);
    }
    return Promise.reject();
  },

  getUserID: () => {
    const authData = localStorage.getItem(dataKey);
    if (authData) {
      const parsedUser = JSON.parse(authData);
      return Promise.resolve(parsedUser.userId);
    }
    return Promise.reject();
  },

  getAccessToken: (): string | null => {
    return localStorage.getItem(tokenKey);
  },

  getRefressToken: (): string | null => {
    return localStorage.getItem(refreshKey);
  },

  resetPassword: async (data: {
    password: string;
    token: string;
    password_confirm: string;
  }) => {
    const params: AxiosRequestConfig = {
      method: "POST",
      url: "/auth/v2/password/reset-confirm",
      data,
    };

    const response = await axiosClient.request({ ...params });

    return response?.data;
  },
};

export default authProvider;
