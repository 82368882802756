import { Box } from "@mui/material";
import colors from "constants/colors";

export const statusChipStyle = {
  error: { color: colors.Error500, bg: colors.Error300 },
  disabled: { color: "#555566", bg: "#CCCCDD" },
  neutral: { color: colors.Grey700, border: "#CCCCDD", bg: "transparent" },
  success: { color: colors.Success500, bg: colors.Success300 },
};

const StatusChip = ({
  icon,
  status,
  text,
  style,
}: {
  icon?: JSX.Element;
  status?: any;
  text?: string;
  style?: keyof typeof statusChipStyle;
}) => {
  const { bg, color, border, name } = status
    ? status
    : text && style
    ? { border: "transparent", ...statusChipStyle[style], name: text }
    : { name: "", bg: "", border: "", color: "" };
  return (
    <Box
      mb="0.25rem"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontSize: 13,
        padding: "4px 8px",
        borderRadius: 100,
        background: bg,
        color,
        border: border ? `1px solid ${border}` : "none",
      }}
    >
      {icon ? (
        <Box
          sx={{
            marginRight: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon}
        </Box>
      ) : null}
      {name}
    </Box>
  );
};

export default StatusChip;
