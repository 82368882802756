/* eslint-disable react/no-unstable-nested-components */
import { Logout } from "@mui/icons-material";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Alert, Snackbar, Tabs, Typography, Tab } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { IReduxState } from "interfaces/redux";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { userLogout } from "redux/auth/action";
import { clearToast } from "redux/toast/action";
import trackPathForAnalytics from "utils/trackPageForAnalytics";

const AuthTemplate = (props: any) => {
  const navigate = useNavigate();
  const { window, children, title } = props;
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const { toast, auth } = useSelector((state: IReduxState) => state);

  const analytics = useCallback(() => {
    if (title) {
      trackPathForAnalytics({ path: pathname, search, title });
    }
  }, [pathname, search]);

  useEffect(() => {
    if (title) {
      analytics();
    }
  }, [title, analytics]);

  useEffect(() => {
    if (window !== undefined) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    if (!auth?.user) {
      navigate("/login");
    }
  }, [auth?.user]);

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={typeof toast.type === "string"}
        autoHideDuration={20000}
        onClose={() => {
          dispatch(clearToast());
        }}
      >
        <Alert
          onClose={() => {
            dispatch(clearToast());
          }}
          severity={toast.type ?? "warning"}
          sx={{ width: "100%" }}
        >
          <>
            <Typography sx={{ whiteSpace: "pre-line" }}>
              {toast.title}
            </Typography>
            {typeof toast.description === "function" && toast.description()}
          </>
        </Alert>
      </Snackbar>
      <CssBaseline />
      <AppBar
        sx={{
          width: { sm: `100%` },
          ml: { sm: `0` },
          height: "72px",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <Link style={{ height: 40, width: 40 }} to="/">
              <img
                src={`${process.env.PUBLIC_URL}/logo/app_icon-${process.env.REACT_APP_ENV}.png`}
                alt="SV"
                width={40}
              />
            </Link>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={location.pathname.includes("accounts") ? 1 : 0}
              centered
            >
              <Tab
                sx={{ fontSize: "1rem" }}
                icon={<PersonOutlineIcon />}
                iconPosition="start"
                component="a"
                label="Wallet Holders"
                href="/"
                onClick={(
                  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  e.preventDefault();
                  navigate("/");
                }}
              />
              <Tab
                sx={{ fontSize: "1rem" }}
                icon={<AccountBalanceWalletOutlinedIcon />}
                iconPosition="start"
                component="a"
                label="USD Account"
                href="/accounts/USD"
                onClick={(
                  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  e.preventDefault();
                  navigate("/accounts/USD");
                }}
              />
            </Tabs>
          </Box>
          <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            <Tabs value={0} centered>
              <Tab
                sx={{ fontSize: "1rem" }}
                icon={<Logout />}
                iconPosition="start"
                component="a"
                label="Log out"
                href="/"
                onClick={(
                  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  e.preventDefault();
                  dispatch(userLogout());
                  navigate("/login");
                }}
              />
            </Tabs>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginTop: "64px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AuthTemplate;
