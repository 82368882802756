import colors from "constants/colors";

export const setAppBar = () => ({
  MuiAppBar: {
    styleOverrides: {
      root: () => ({
        background: colors.White,
        boxShadow: "none",
      }),
    },
  },
});
