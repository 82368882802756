import {
  RISK_STATUS,
  USER_STATUS,
  VERIFICATION_STATUS,
  WALLET_STATUS,
} from "interfaces/wallet-holder";

// export const getRiskStatus = (compliance: string) => {
//   if (!compliance) {
//     return '';
//   }
//   switch (compliance.toLowerCase()) {
//     case COMPLIANCE_STATUS.CLEARED:
//       return RISK_STATUS.CLEARED;
//     case COMPLIANCE_STATUS.REJECTED:
//       return RISK_STATUS.BLOCKED;
//     default:
//       return RISK_STATUS.SUSPENDED;
//   }
// };

// export const getVerificationStatus = (kycMode: string, kycSub?: string) => {
//   switch (kycMode.toLowerCase()) {
//     case KYC_STATUS.NOT_SUBMITTED:
//       return VERIFICATION_STATUS.NOT_APPROVED;
//     case KYC_STATUS.PENDING:
//       return VERIFICATION_STATUS.PENDING;
//     case KYC_STATUS.IN_PROGRESS:
//       return VERIFICATION_STATUS.APPROVAL_SUBMITTED;
//     case KYC_STATUS.REJECTED:
//       return VERIFICATION_STATUS.REJECTED;
//     case KYC_STATUS.APPROVED:
//       return VERIFICATION_STATUS.APPROVED;
//     case KYC_STATUS.COMPLETED:
//       if (kycSub === KYC_SUB_STATUS.APPROVED) {
//         return VERIFICATION_STATUS.APPROVED;
//       }
//       return VERIFICATION_STATUS.REJECTED;
//     default:
//   }
// };

export const renderWalletStatus = (params: any) => {
  const verificationStatus = params.verification_status?.toLowerCase();
  const riskStatus = params.risk_status?.toLowerCase();

  if (params.activation_status === WALLET_STATUS.ARCHIVED.code) {
    return WALLET_STATUS.ARCHIVED;
  }

  if (verificationStatus === VERIFICATION_STATUS.NOT_SUBMITTED) {
    return WALLET_STATUS.WH_NotSubmitted;
  }

  if (
    riskStatus === RISK_STATUS.SUSPENDED &&
    verificationStatus === VERIFICATION_STATUS.SUBMITTED
  ) {
    return WALLET_STATUS.WH_ApprovalSubmitted;
  }

  if (
    riskStatus === RISK_STATUS.CLEARED &&
    verificationStatus === VERIFICATION_STATUS.APPROVED
  ) {
    return WALLET_STATUS.Active;
  }

  if (
    riskStatus === RISK_STATUS.BLOCKED &&
    verificationStatus === VERIFICATION_STATUS.APPROVED
  ) {
    return WALLET_STATUS.BLOCKED;
  }

  if (
    riskStatus === RISK_STATUS.SUSPENDED &&
    verificationStatus === VERIFICATION_STATUS.APPROVED
  ) {
    return WALLET_STATUS.SUSPENDED;
  }

  if (
    riskStatus === RISK_STATUS.SUSPENDED &&
    verificationStatus === VERIFICATION_STATUS.REJECTED
  ) {
    return WALLET_STATUS.WH_Rejected;
  }

  return undefined;
};

export const renderUserStatus = (status: string) => {
  const s = status?.toLowerCase();
  const userStatus = USER_STATUS[s]
    ? USER_STATUS[s]
    : USER_STATUS.not_activated;
  return (
    <span
      style={{
        textAlign: "center",
        display: "inline-block",
        fontSize: 13,
        padding: "4px 8px",
        borderRadius: 100,
        marginBottom: "3px",
        background: userStatus?.bg,
        color: userStatus?.color,
        border: userStatus?.border ? `1px solid ${userStatus.border}` : "none",
      }}
    >
      {userStatus?.name}
    </span>
  );
};
