/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable no-param-reassign */
import produce, { Draft } from "immer";
import { IReduxAuthState, IReduxBaseAction } from "interfaces/redux";
import jwt_decode from "jwt-decode";
import {
  USER_CHECK,
  USER_CHECK_SUCCESS,
  USER_LOGIN,
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REFRESH_TOKEN,
  USER_REFRESH_TOKEN_SUCCESS,
} from "./types";

export const initialState = {
  error: null,
  pending: false,
  user: null,
};

const myReducer = (
  state: IReduxAuthState = initialState,
  action: IReduxBaseAction
) =>
  produce(state, (draft: Draft<IReduxAuthState>) => {
    switch (action?.type) {
      case USER_LOGIN:
        draft.pending = true;
        draft.error = null;
        draft.user = null;
        localStorage.setItem('user.email', action.payload['email']);
        break;
      case USER_REFRESH_TOKEN:
        draft.pending = true;
        break;
      case USER_LOGIN_SUCCESS:
      case USER_REFRESH_TOKEN_SUCCESS:
        // eslint-disable-next-line no-case-declarations
        const decode: any = jwt_decode(action.payload.access_token);
        draft.pending = false;
        draft.error = false;
        draft.user = decode.user;
        break;
      case USER_LOGIN_ERROR:
        draft.pending = false;
        draft.error = true;
        draft.user = null;
        break;
      case USER_CHECK:
        draft.pending = true;
        draft.error = null;
        draft.user = null;
        break;
      case USER_CHECK_SUCCESS:
        draft.pending = false;
        draft.error = false;
        draft.user = {
          ...action.payload,
        };
        break;
      case USER_LOGOUT:
        draft.user = null;
        break;
      default:
    }
  });

export default myReducer;
