import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { AxiosError } from "axios";
import SeaInput from "components/form/SeaInput";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { CustomError } from "interfaces/error";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toast/action";
import authProvider from "utils/authProvider";
import { isPasswordStrong } from "utils/regex";
import { t } from "utils/translate";
import { useQuery } from "utils/useQuery";

export interface IResetForm {
  password: string;
  confirm_password: string;
}

const ResetPassword = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const resetFormRef = useRef<FormikProps<IResetForm>>(null);
  const [isReset, setIsReset] = useState(false);

  const matches = useMediaQuery("(min-width:600px)");

  const submit = (
    values: IResetForm,
    { setSubmitting }: FormikHelpers<IResetForm>
  ) => {
    authProvider
      .resetPassword({
        password: values.password,
        password_confirm: values.confirm_password,
        token: query.get("token") || "",
      })
      .then(() => {
        setIsReset(true);
      })
      .catch((err: AxiosError<CustomError>) => {
        setSubmitting(false);
        dispatch(
          showToast({
            type: "error",
            title:
              err.response?.data.error.message ?? "Can not reset password.",
          })
        );
      });
  };
  const btnRef = useRef<any>();

  useEffect(() => {
    if (btnRef.current) {
      btnRef.current.click();
    }
    document.title = "SeaBoard - Set Password";
  }, []);

  if (isReset) {
    return (
      <Box>
        <Card
          variant="outlined"
          sx={{ width: matches ? 400 : 300, textAlign: "center" }}
        >
          <CardContent>
            <Box textAlign="center" py={4}>
              <img
                src={`${process.env.PUBLIC_URL}/images/Icon.png`}
                alt="success"
                width="80px"
              />
            </Box>
            <Box sx={{ fontSize: "24px", fontWeight: 600 }}>
              Password Updated
            </Box>
            <Box pb={4}>You have successfully updated your password.</Box>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box>
      <Card variant="outlined" sx={{ width: matches ? 400 : 300 }}>
        <CardContent>
          <Box sx={{ typography: "h1", p: "0 0 30px" }}>Set Password</Box>
          <Formik
            validateOnMount={true}
            innerRef={resetFormRef}
            initialValues={{
              password: "",
              confirm_password: "",
            }}
            onSubmit={submit}
            validate={(values: IResetForm) => {
              const errors: any = {};

              if (!values.password) {
                errors.password = t("field_blank_error_message");
              } else if (!isPasswordStrong(values.password)) {
                errors.password = t(
                  "field_password_is_not_strong_errror_message"
                );
              }
              if (!values.confirm_password) {
                errors.confirm_password = t("field_blank_error_message");
              }
              if (values.confirm_password !== values.password) {
                errors.confirm_password = t(
                  "field_confirm_password_not_match_error_message"
                );
              }

              return errors;
            }}
          >
            {({ handleSubmit, isSubmitting, isValid }) => (
              <form onSubmit={handleSubmit}>
                <Stack gap={3}>
                  <SeaInput
                    name="password"
                    label={t("new_password")}
                    fullWidth
                    type="password"
                  />
                  <SeaInput
                    name="confirm_password"
                    label={t("confirm_password")}
                    fullWidth
                    type="password"
                  />
                  <Box
                    display="flex"
                    bgcolor="#EFEAFF"
                    p={2}
                    borderRadius="8px"
                  >
                    <InfoIcon sx={{ color: "#5D31FF", mr: 2, mt: 1 }} />
                    <Box>
                      <Box
                        sx={{ color: "#5D31FF", fontWeight: 500, fontSize: 18 }}
                      >
                        Password must be:
                      </Box>
                      <Box sx={{ fontSize: "14px" }}>{t("password_rule")}</Box>
                    </Box>
                  </Box>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting || !isValid}
                  >
                    {isSubmitting ? "Submitting" : "Continue"}
                  </Button>
                </Stack>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};
export default ResetPassword;
