import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const WalletHolderTabMenu = ({ id }: { id: string }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  // const params = useParams<{ type: string }>();

  const location = useLocation();

  const tabs = [
    {
      label: "Profile",
      key: "show",
      link: `/wallet-holder/show/${id}`,
    },
    {
      label: "Card",
      key: "card",
      link: `/wallet-holder/card/${id}`,
    },
  ];

  useEffect(() => {
    const findActiveTabIndex = tabs.findIndex(
      (item) => item.link === location.pathname
    );
    setValue(findActiveTabIndex > -1 ? findActiveTabIndex : 0);
  }, [id]);

  return (
    <Tabs value={value} onChange={() => {}} sx={{ marginBottom: 3 }}>
      {tabs.map((item) => (
        <Tab
          key={item.key}
          onClick={() => {
            navigate(item.link);
          }}
          label={item.label}
        />
      ))}
    </Tabs>
  );
};

export default WalletHolderTabMenu;
