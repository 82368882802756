import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormLabel,
  Stack,
  Typography,
} from "@mui/material";
import colors from "constants/colors";
import { formatMoney, formatMoneyWithDecimal } from "utils/formatMoney";
import { t } from "utils/translate";
import BulkTransferOTP from "./BulkTransactionOTP";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useContext, useState } from "react";
import { BulkTransferProvider } from "./BulkTransfer";
import { confirmBulkDisbursals, bulkJobResendOtp } from "utils/apiProvider";
import { showToast } from "redux/toast/action";
import { useDispatch } from "react-redux";
import * as helpers from "./helpers";

const BulkTransferConfirm = () => {
  const dispatch = useDispatch();

  const contextData = useContext(BulkTransferProvider);
  const [otpvalue, setOtpValue] = useState("");
  const numberOfTransfer =
    contextData.jobInfo.transactionInfo.totalTransactions;
  const totalAmount = contextData.jobInfo.transactionInfo.totalAmount;
  const showSuccessToast = (msg: string) => {
    dispatch(
      showToast({
        type: "success",
        title: msg,
      })
    );
  };
  const showErrorToast = (msg: string) => {
    dispatch(
      showToast({
        type: "error",
        title: msg,
      })
    );
  };
  const confirmTransfer = async () => {
    try {
      confirmBulkDisbursals({
        otp: otpvalue,
        otpId: contextData.jobInfo.otpCode,
        audience: "BULK_PAYOUT",
        job_id: contextData.jobInfo.jobId,
      });
      contextData.setJobInfo({
        ...contextData.jobInfo,
        status: helpers.JobStatus.DISBUSTAL_INITITATED,
      });
      showSuccessToast("Bulk transfer Initiated Successfully");
      contextData.setStep(4);
    } catch (error) {
      const errorMessage = (error as Error).message || "Something went wrong";
      showErrorToast(errorMessage);
    }
  };

  const resendOtp = async () => {
    try {
      await bulkJobResendOtp({
        otpId: contextData.jobInfo.otpCode,
        job_id: contextData.jobInfo.jobId,
      });

      showSuccessToast("OTP has been resent successfully to your email.");
      return;
    } catch (error) {
      console.log(error);
      return;
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ width: 400 }}>
          <CardContent sx={{ margin: ["10px", "10px", "15px"] }}>
            <Box>
              <ArrowBackIcon
                onClick={() => {
                  // if (step > 1) {
                  //   setStep(step - 1);
                  // } else {
                  //   navigate("/");
                  // }
                }}
              />
            </Box>
            <Stack gap={2} mt={3}>
              <Typography sx={{ fontWeight: 700, fontSize: "28px" }}>
                {t("confirm_transfer")}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormLabel>{t("no_of_transfers")}</FormLabel>
                <Typography color={colors.Black}>{numberOfTransfer}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormLabel>{t("total_disbursable")}</FormLabel>
                <Typography color={colors.Black}>
                  {formatMoneyWithDecimal(formatMoney(totalAmount))}{" "}
                </Typography>
              </Box>
              <Divider />
              <Box>
                <BulkTransferOTP
                  otpvalue={otpvalue}
                  setOtpValue={setOtpValue}
                  resendOtp={resendOtp}
                />
              </Box>
              <Button
                variant="contained"
                type="submit"
                onClick={() => {
                  confirmTransfer();
                }}
              >
                {t("confirm")}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default BulkTransferConfirm;
