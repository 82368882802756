import ImportCsv from "./ImportCSV";
import BulkTransferConfirm from "./ConfirmTransfer";
import BulkTransferImportInProgress from "./CSVImportProgress";

import { createContext, useMemo, useState } from "react";

interface jobInfo {
  jobId: string;
  status: string;
  info: string[];
  csvResult: string;
  otpCode: string;
  transactionInfo: {
    totalAmount: number;
    totalTransactions: number;
  };
}

const InitialJobInfo: jobInfo = {
  jobId: "",
  status: "",
  info: [],
  csvResult: "",
  otpCode: "",
  transactionInfo: {
    totalAmount: 0,
    totalTransactions: 0,
  },
};
interface BulkTransferProviderProps {
  file: any[];
  step: number;
  jobId: string;
  csvResult: string;
  otpCode: string;
  info: string[];
  status: string;
  setStatus: (t: string) => void;
  setInfo: (t: string[]) => void;
  setStep: (t: number) => void;
  setFile: (t: unknown[]) => void;
  setJobId: (t: string) => void;
  setOtpCode: (t: string) => void;
  setCsvResult: (t: string) => void;
  transactionInfo: {
    totalAmount: number;
    totalTransactions: number;
  };
  setTransactionInfo: (info: {
    totalAmount: number;
    totalTransactions: number;
  }) => void;
  jobInfo: jobInfo;
  setJobInfo: (t: jobInfo) => void;
}

export const BulkTransferProvider = createContext<BulkTransferProviderProps>({
  file: [],
  info: [],
  setInfo: (t) => console.warn("console.warn - ", t),
  step: 1,
  jobId: "",
  otpCode: "",
  transactionInfo: {
    totalAmount: 0,
    totalTransactions: 0,
  },
  status: "",
  setStatus: (t) => console.warn("console.warn - ", t),
  csvResult: "",
  setCsvResult: (t) => console.warn("console.warn - ", t),
  setTransactionInfo: (info) => console.warn("console.warn - ", info),
  setJobId: (t) => console.warn("console.warn - ", t),
  setStep: (t) => console.warn("console.warn - ", t),
  setFile: (t) => console.warn("console.warn - ", t),
  setOtpCode: (t) => console.warn("console.warn - ", t),
  jobInfo: InitialJobInfo,
  setJobInfo(t) {
    console.warn("console.warn - ", t);
  },
});

const BulkTransfer = () => {
  const [step, setStep] = useState<number>(1);
  const [file, setFile] = useState<unknown[]>([]);
  const [jobId, setJobId] = useState<string>("");
  const [otpCode, setOtpCode] = useState<string>("");
  const [csvResult, setCsvResult] = useState<string>("");
  const [info, setInfo] = useState<string[]>([]);
  const [status, setStatus] = useState<string>("");
  const [transactionInfo, setTransactionInfo] = useState<{
    totalAmount: number;
    totalTransactions: number;
  }>({
    totalAmount: 0,
    totalTransactions: 0,
  });
  const [jobInfo, setJobInfo] = useState<jobInfo>(InitialJobInfo);

  const contextData = useMemo(
    () => ({
      file,
      step,
      jobId,
      otpCode,
      setOtpCode,
      setStep,
      setFile,
      setJobId,
      transactionInfo,
      setTransactionInfo,
      csvResult,
      setCsvResult,
      info,
      setInfo,
      status,
      setStatus,
      jobInfo,
      setJobInfo,
    }),
    [
      file,
      step,

      otpCode,
      jobId,
      setOtpCode,

      setStep,
      setFile,
      transactionInfo,
      setTransactionInfo,
      csvResult,
      setCsvResult,
      info,
      setInfo,
      status,
      setStatus,
      jobInfo, // Add this line
      setJobInfo, // Add this line
    ]
  );

  const renderUiByStep = () => {
    switch (step) {
      case 2:
        return <BulkTransferImportInProgress />;
      case 3:
        return <BulkTransferConfirm />;
      case 4:
        return <BulkTransferImportInProgress />;
      default:
        return <ImportCsv />;
    }
  };

  return (
    <BulkTransferProvider.Provider value={contextData}>
      {renderUiByStep()}
    </BulkTransferProvider.Provider>
  );
};

export default BulkTransfer;
