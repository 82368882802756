import { Box, Button, Typography } from "@mui/material";

import CountDown from "components/form/Countdown";
import colors from "constants/colors";

import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";

import { useLocation } from "react-router-dom";

import trackPathForAnalytics from "utils/trackPageForAnalytics";

interface BulkTransferOTPProps {
  otpvalue: string;
  setOtpValue: (value: string) => void;
  resendOtp: () => Promise<void>;
}

const BulkTransferOTP = ({
  otpvalue,
  setOtpValue,
  resendOtp,
}: BulkTransferOTPProps) => {
  const [disabledResend, setDisableResend] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    trackPathForAnalytics({
      path: pathname,
      search: "",
      title: "OTP Entry",
    });
  }, []);

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ typography: "h3" }}>
          <Typography display="inline" color={colors.Black} fontWeight={600}>
            Enter OTP
          </Typography>
        </Box>
        <Typography display="inline" color={colors.Grey700}>
          We&apos;ve sent a 6-digit code to{" "}
        </Typography>

        <Typography display="inline" color={colors.Primary500}>
          {localStorage.getItem("user.email") ?? "your email address."}
        </Typography>
      </Box>
      <OtpInput
        value={otpvalue}
        onChange={(c: string) => {
          setOtpValue(c);
        }}
        numInputs={6}
        inputStyle={{
          height: "50px",
          width: window.innerWidth < 500 ? "40px" : "50px",
          borderRadius: "8px",
          border: `1px solid ${colors.Grey300}`,
          ":focusVisible": {
            outline: "red auto 1px",
          },
        }}
        focusStyle={{
          outline: "none",
          border: "2px solid #5D31FF",
          borderRadius: "8px",
        }}
        containerStyle={{
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          variant="text"
          sx={{ fontSize: "16px", mb: 2, textDecoration: "underline" }}
          onClick={async () => {
            resendOtp();
          }}
          disabled={disabledResend}
        >
          Resend OTP{" "}
          {disabledResend && (
            <CountDown seconds={60} onFinish={() => setDisableResend(false)} />
          )}
        </Button>
      </Box>
    </>
  );
};

export default BulkTransferOTP;
