/* eslint-disable no-nested-ternary */
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import colors from "constants/colors";
import { IBankAccountProps, initBankAccount } from "interfaces/bank-account";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAccounts } from "utils/apiProvider";
import authProvider from "utils/authProvider";
import { formatMoney, formatMoneyWithDecimal } from "utils/formatMoney";

const AccountList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<IBankAccountProps[]>([initBankAccount]);

  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    const userId = await authProvider.getUserID();
    getAccounts(userId).then((resp: any) => {
      setRows(
        resp.sort((a: IBankAccountProps, b: IBankAccountProps) =>
          a.currency_name > b.currency_name
            ? 1
            : b.currency_name > a.currency_name
              ? -1
              : 0
        )
      );
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchUsers();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "20px 0",
        }}
      >
        <Typography variant="h1">Accounts</Typography>
      </Box>
      {loading ? (
        <Card>
          <CardContent>Loading ...</CardContent>
        </Card>
      ) : (
        <Card>
          <CardContent>
            <Box>
              {rows.map((item) => (
                <Box key={item.currency}>
                  <Button
                    variant="text"
                    fullWidth
                    onClick={() => navigate(`/accounts/${item.currency}`)}
                    sx={{
                      ":hover": {
                        textDecoration: "none",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-between",
                        margin: "20px 0",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/countries/${item.currency.toLowerCase()}.png`}
                          alt={item.currency}
                          width="50px"
                          height="50px"
                          style={{ marginRight: "20px" }}
                        />
                        <Box sx={{ textAlign: "left" }}>
                          <Typography sx={{ color: colors.Black }}>
                            {item.currency_name}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "14px", color: colors.Grey700 }}
                          >{`${formatMoneyWithDecimal(
                            formatMoney(item?.balance)
                          )} ${item?.currency}`}</Typography>
                        </Box>
                      </Box>
                      <Box sx={{ alignSelf: "center" }}>
                        <KeyboardArrowRightIcon
                          sx={{ color: colors.Grey500 }}
                        />
                      </Box>
                    </Box>
                  </Button>
                  <Divider />
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default AccountList;
