import { Box, Button, Modal, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { style } from "themes/modal";
import { t } from "utils/translate";
import { Parser } from '@json2csv/plainjs';
import { showToast } from "redux/toast/action";
import dayjs from "dayjs";

const ExportTransfers = ({ rows }: any) => {
  const linkRef = useRef<any>();
  const [exporting, setExporting] = useState(false);
  const dispatch = useDispatch();

  const handleExport = async () => {
    if (rows.length > 0) {
      setExporting(true);
      downloadCSV();
      setExporting(false);
      dispatch(
        showToast({
          type: "success",
          title: t('export_successful'),
        })
      );
    }
    else {
      dispatch(
        showToast({
          type: "error",
          title: t('export_failure'),
        })
      );
    }
  };

  const downloadCSV = () => {
    try {
      const fields = [
        {
          label: 'Created At',
          value: `created_at`,
        },
        {
          label: 'Updated At',
          value: `updated_at`,
        },
        {
          label: 'Transfer ID',
          value: `id`,
        },
        {
          label: 'Type',
          value: `type`,
        },
        {
          label: 'Amount',
          value: `amount`,
        },
        {
          label: 'Currency',
          value: `currency_code`,
        },
        {
          label: 'State',
          value: `state`,
        },
        {
          label: 'Sender ID',
          value: `sender_id`,
        },
        {
          label: 'Sender Given Name',
          value: `sender_first_name`,
        },
        {
          label: 'Sender Surname',
          value: `sender_last_name`,
        },
        {
          label: 'Sender Email',
          value: `sender_email`,
        },
        {
          label: 'Recipient ID',
          value: `recipient_id`,
        },
        {
          label: 'Recipient Given Name',
          value: `recipient_first_name`,
        },
        {
          label: 'Recipient Surname',
          value: `recipient_last_name`,
        },

        {
          label: 'Recipient Email',
          value: `recipient_email`,
        },

        {
          label: 'Comments',
          value: `comments`,
        }
      ];
      const opts = { fields };
      const parser = new Parser(opts);
      const csv = parser.parse(rows);
      downloadCSVFile(csv);
      console.log(csv);
    } catch (err) {
      console.error(err);
    }
  }

  const downloadCSVFile = (_data: BlobPart) => {
    const element = document.createElement("a");
    const file = new Blob([_data], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = getFileName();
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
  }

  function getFileName() {
    const currentDate = dayjs();
    const formattedDate = currentDate.format('YYYYMMDD-HHmmss');
    return `SB Transfers ${formattedDate}.csv`;
  }

  return (
    <>
      {exporting && (
        <Modal open={exporting}>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Exporting...
            </Typography>
          </Box>
        </Modal>
      )}
      <Button
        variant="outlined"
        onClick={handleExport}
        sx={{
          border: "none",
          color: "#5D31FF",
          textDecoration: "underline",
          fontSize: "14px",
          padding: 0,
          textAlign: "center",
          display: "inline-block",
          float: "right",
          marginTop: [1, 1, 0, 0],
          marginBottom: 3,
          "&:hover": {
            border: "none",
            background: "none",
            color: "#381C9F",
            textDecoration: "underline",
          },
          "&.Mui-focusVisible": {
            background: "none",
            color: "#381C9F",
          },
        }}
      >
        {t("export_transfers")}
      </Button>
      <a href="/" ref={linkRef} style={{ display: "none" }}>
        a
      </a>
    </>
  );
};

export default ExportTransfers;

