import { Box } from "@mui/material";
import { IWalletHolderProps } from "interfaces/wallet-holder";
import { diffDaysNow } from "./dateDiffDays";

export const cardsStatusText = (
  cardStatuses: IWalletHolderProps["card_statuses"]
) =>
  cardStatuses === undefined || cardStatuses.length === 0
    ? "No physical cards"
    : cardStatuses.length === 1
    ? `1 ${cardStatuses[0].status.toLowerCase()} physical card`
    : `${cardStatuses.length}/${process.env.REACT_APP_MAX_PHYSICAL_CARDS} physical cards`;

export function CardStatusList(p: {
  cardStatuses: IWalletHolderProps["card_statuses"];
}) {
  const numDays = (str: string) => {
    const days = diffDaysNow(new Date(str));
    return days === 0 ? "Today" : days === 1 ? "1 day ago" : `${days} days ago`;
  };
  return (
    <>
      {(p.cardStatuses ?? []).map((card) => (
        <Box sx={{ mb: 1 }} key={card.id}>
          <div>
            <strong>
              {card.masked_card_number} ({card.status})
            </strong>
          </div>
          <div>
            Created: {card.created_at ? numDays(card.created_at) : "N/A"}
          </div>
          <div>Issued: {card.issued ? numDays(card.created_at) : "N/A"}</div>
          <div>Sent: {card.sent_at ? numDays(card.created_at) : "N/A"}</div>
        </Box>
      ))}
      <Box>
        {
          (p.cardStatuses ?? []).filter((card) => card.status !== "ARCHIVED")
            .length
        }
        /{process.env.REACT_APP_MAX_PHYSICAL_CARDS} card slots used
      </Box>
    </>
  );
}
