import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ErrorScreen = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate("/");
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  return <></>;
};

export default ErrorScreen;
