/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Close } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Button,
  Card,
  CardContent,
  Input,
  InputLabel,
  Link as MLink,
  Stack,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { AxiosError } from "axios";
import PastTransactionTable from "components/account/PastTransactionTable";
import BulkTransferForm from "components/transfer/BulkTransfer";
import { DangerIcon } from "components/wallet-holder/detail/WhDetailHeader";
import { IBankAccountProps } from "interfaces/bank-account";
import { CustomError } from "interfaces/error";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { showToast } from "redux/toast/action";
import { style } from "themes/modal";
import { getAccountDetail } from "utils/apiProvider";
import { formatMoney, formatMoneyWithDecimal } from "utils/formatMoney";
import ExportTransfers from "./export_transfers";
import authProvider from "utils/authProvider";
import { t } from "utils/translate";
const AccountDetail = () => {
  const params = useParams<{ id: string }>();
  const [currency, setCurrency] = useState<IBankAccountProps>({
    currency: "",
    currency_name: "",
    balance: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState<any>(null);
  const [importing, setImporting] = useState(false);
  const [showError, setShowError] = useState<any>(false);
  const [rows, setRows] = useState<any[]>([]);

  const fetchAccountDetail = async () => {
    const userId = await authProvider.getUserID();
    if (params?.id && userId) {
      getAccountDetail(params.id, userId)
        .then((resp: any) => {
          setCurrency(resp);
        })
        .catch((err: AxiosError<CustomError>) => {
          if (err.response?.status !== 401) {
            dispatch(
              showToast({
                type: "error",
                title: err.response?.data.error.message ?? "Can not get data.",
              })
            );
          }
        });
    }
  };

  useEffect(() => {
    fetchAccountDetail();
  }, []);

  useEffect(() => {
    if (!importing) {
      setFile(null);
    }
  }, [importing]);

  const handleChange = (e: any) => {
    const fileTarget = e.target.files[0];
    if (fileTarget.size > 5000000) {
      dispatch(
        showToast({
          type: "error",
          title: "Exceed allowed size",
        })
      );
    } else if (fileTarget.type !== "text/csv") {
      dispatch(
        showToast({
          type: "error",
          title: "Invalid file type",
        })
      );
    } else {
      setFile(fileTarget);
      setImporting(true);
    }
    e.target.value = "";
  };

  return (
    <>
      {importing && file && (
        <BulkTransferForm
          file={file}
          setImporting={setImporting}
          setShowError={setShowError}
        />
      )}

      <Box sx={{ display: importing ? "none" : "block" }}>
        <Box>
          <Link to="/accounts" style={{ fontSize: "14px" }}>
            Accounts
          </Link>
          <ArrowForwardIosIcon sx={{ fontSize: 10, marginLeft: 1 }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: [
              "flex-start",
              "flex-start",
              "flex-start",
              "center",
              "center",
            ],
            flexDirection: ["column", "column", "column", "row", "row"],
          }}
        >
          <Box
            sx={{
              display: "flex",
              margin: "10px 0 30px",
              alignItems: "center",
            }}
          >
            <Box sx={{ typography: "h1", marginRight: 2 }}>
              <img
                src={`${
                  process.env.PUBLIC_URL
                }/countries/${params.id?.toLocaleLowerCase()}.png`}
                alt={params.id}
                width={40}
              />
            </Box>
            <Box>
              <Box sx={{ typography: "h1" }}>{currency?.currency_name}</Box>
              <Box>{`${formatMoneyWithDecimal(
                formatMoney(currency?.balance)
              )} ${currency.currency}`}</Box>
            </Box>
          </Box>
          <Box
            sx={{ display: "none", alignItems: "center", mb: [3, 3, 3, 0, 0] }}
          >
            <Box>
              <InputLabel
                sx={{
                  border: "1px solid #BEADFF",
                  color: "#381C9F",
                  borderRadius: "100px",
                  fontSize: "14px",
                  padding: "10px 20px",
                  textAlign: "center",
                  display: "inline-block",
                  ml: "10px",
                  "&:hover": {
                    background: "rgba(93, 49, 255, 0.04)",
                  },
                }}
                htmlFor="upload-photo"
              >
                Bulk transfer
              </InputLabel>

              <Input
                sx={{ display: "none" }}
                id="upload-photo"
                type="file"
                onChange={handleChange}
              />
            </Box>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/bulk-transfer/import");
              }}
              sx={{ fontSize: 14 }}
            >
              {t("bulk_transfer")}
            </Button>
            <MLink
              href="/files/Bulk-transfer.csv"
              download="Bulk transfer.csv"
              target="_blank"
              sx={{
                marginLeft: "10px",
                display: "block",
                textAlign: "right",
                fontSize: "14px",
                color: "#5D31FF",
                textDecoration: "underline",
                fontWeight: 500,
                "&:hover": {
                  color: "#381C9F",
                },
              }}
            >
              Download Template
            </MLink>
          </Box>
        </Box>
        <Card>
          <CardContent sx={{ margin: ["10px", "10px", "20px"] }}>
            <Stack gap="24px">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h2">Past Transfers</Typography>
                <ExportTransfers rows={rows} />
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/bulk-transfer/import");
                  }}
                  sx={{ fontSize: 14 }}
                >
                  {t("bulk_transfer")}
                </Button>
              </Box>

              <Box>
                {params.id && (
                  <PastTransactionTable
                    id={params.id}
                    isImporting={importing}
                    currency={currency.currency}
                    rows={rows}
                    setRows={setRows}
                  />
                )}
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default AccountDetail;
