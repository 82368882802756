import colors from "constants/colors";

export const setTableStyles = () => ({
  MuiTableHead: {
    styleOverrides: {
      root: () => ({
        "& .MuiTableCell-root": {
          backgroundColor: colors.Secondary100,
          verticalAlign: "top",
        },
      }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: () => ({ verticalAlign: "top" }),
    },
  },
});
