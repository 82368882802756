import { useEffect } from "react";

const Verified = () => {
  //   const params = useParams();

  useEffect(() => {
    // if (params.id && params.email) {
    //   handleStorage('seavault_web.verified', {
    //     id: params.id,
    //     email: params.email
    //   });
    // }
    window.opener = null;
    window.open("", "_self");
    window.close();
  }, []);

  return <></>;
};

export default Verified;
