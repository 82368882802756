/* eslint-disable no-lonely-if */
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Stack,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import SeaAutocomplete from "components/form/SeaAutocomplete";
import SeaDatepicker from "components/form/SeaDatepicker";
import SeaInput from "components/form/SeaInput";
import SeaSelect from "components/form/SeaSelect";
import colors from "constants/colors";
import dayjs from "dayjs";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { ICountryProps, IIdentificationTypeProps } from "interfaces/common";
import { CustomError } from "interfaces/error";

import { IReduxState, IRoles } from "interfaces/redux";
import {
  GENDER,
  IWalletHolderProps,
  TITLE,
  wallerHolderInit,
} from "interfaces/wallet-holder";
import {
  ClassAttributes,
  Key,
  LiHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { showToast } from "redux/toast/action";
import {
  addUser,
  getCountries,
  getIdTypes,
  getNationalities,
  getPhoneCodes,
  getWalletHolderDetail,
  updateUser,
} from "utils/apiProvider";
import { getChangedValues } from "utils/getDifferenceBetObj";
import { t } from "utils/translate";
import { uniqueArray } from "utils/uniqueArray";
import { z, ZodString } from "zod";
import { formatPhoneNumber } from "utils/formatPhoneNumber";

export class ValidationError extends Error {
  public name = "ValidationError";

  public inner: Array<{ path: string; message: string }> = [];

  public constructor(message: string) {
    super(message);
  }
}

function createValidationResult(error: z.ZodError) {
  const result: Record<string, string> = {};

  for (const x of error.errors) {
    result[x.path.filter(Boolean).join(".")] = x.message;
  }

  return result;
}

export const disallowLeadingAndTrailingSpaces = [
  (str: string) => str[0] !== " " && str[str.length - 1] !== " ",
  { message: "Leading and trailing spaces not allowed" },
] as const;

// Shamelessly borrowed from StackOverflow somewhere
const intlPhoneRegex = /^\+(?:\d\s?){6,14}\d$/;

export const AppDeviceType = z.enum(["phone", "tablet"]);
export const AppDeviceOS = z.enum(["ios", "android"]);

export const Id = z.coerce.number();
export type Id = z.infer<typeof Id>;
export const Timestamp = z.date().or(z.string().transform((x) => new Date(x)));
export const DateOutput = z.string();
export const DateInput = z
  .string()
  .regex(/^\d{4}-([0][1-9]|1[0-2])-([0-2][1-9]|[1-3]0|3[01])$/, {
    message: "Date must follow format YYYY-MM-DD",
  })
  .transform((x) => {
    const [yyyy, mm, dd] = x.split("-").map((x) => +x);
    return new Date(Date.UTC(yyyy, mm - 1, dd));
  });

export const PasswordInput = z.string().min(8);

export const OtpInput = z.string();

//
// JSON type

const InternalJsonLiteral = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.null(),
]);
type InternalJsonLiteral = z.infer<typeof InternalJsonLiteral>;
type InternalJson =
  | InternalJsonLiteral
  | { [key: string]: InternalJson }
  | InternalJson[];
export const Json: z.ZodType<InternalJson> = z.lazy(() =>
  z.union([InternalJsonLiteral, z.array(Json), z.record(Json)])
);
export type Json = z.infer<typeof Json>;

export const CurrencyCodeInput = z
  .string()
  .length(3)
  .refine((x) => x === x.toUpperCase(), {
    message: "Currency codes must be uppercase",
  });

export const Amount = z.string().regex(/^[-]?(?:[0-9]+)*[0-9]+(?:\.[0-9]+)?$/g);
export const NegativeAmount = Amount.refine(
  (x) => x[0] === "-",
  "Must be a negative number"
);
export const PositiveAmount = Amount.refine(
  (x) => x[0] !== "-",
  "Must be a positive number"
);

export const idAndTimestampsBase = {
  id: Id,
  createdAt: Timestamp,
  updatedAt: Timestamp,
};

export const idAndTimestampsStorage = {
  id: Id,
  createdAt: Timestamp.nullable(),
  updatedAt: Timestamp.nullable(),
};

export const IntlPhoneNumberInput = z
  .string()
  .regex(
    intlPhoneRegex,
    "Phone numbers must start with a `+` sign and be followed by only digits and spaces"
  )
  .transform((x) => x.replaceAll(" ", ""));
export const PhoneNumberCountryCodeInput = z
  .string()
  .min(1)
  .max(7)
  .regex(/^\+?[0-9]{1,6}$/)
  .transform((x) => x.replace("+", ""));
export const PhoneNumberLocalInput = z
  .string()
  .min(7)
  .max(12)
  .regex(/^[0-9]{7,12}$/, "Must be 0-9, no spaces, 8-12 digits");

const toTitleCase = (str: string) => {
  const articles = ["a", "an", "the"];
  const conjunctions = ["for", "and", "nor", "but", "or", "yet", "so"];
  const prepositions = [
    "with",
    "at",
    "from",
    "into",
    "upon",
    "of",
    "to",
    "in",
    "for",
    "on",
    "by",
    "like",
    "over",
    "plus",
    "but",
    "up",
    "down",
    "off",
    "near",
  ];

  // The list of spacial characters can be tweaked here
  // const replaceCharsWithSpace = (str: string) => str.replace(/[^0-9a-z&/\\]/gi, ' ').replace(/(\s\s+)/gi, ' ');
  const capitalizeFirstLetter = (str: string) =>
    str.charAt(0).toUpperCase() + str.substr(1);
  const normalizeStr = (str: string) => str.toLowerCase().trim();
  const shouldCapitalize = (
    word: string,
    fullWordList: string[],
    posWithinStr: number
  ) => {
    if (posWithinStr == 0 || posWithinStr == fullWordList.length - 1) {
      return true;
    }

    return !(
      articles.includes(word) ||
      conjunctions.includes(word) ||
      prepositions.includes(word)
    );
  };

  // str = replaceCharsWithSpace(str);
  str = normalizeStr(str);

  let words = str.split(" ");
  if (words.length <= 2) {
    // Strings less than 3 words long should always have first words capitalized
    words = words.map((w) => capitalizeFirstLetter(w));
  } else {
    for (let i = 0; i < words.length; i++) {
      words[i] = shouldCapitalize(words[i], words, i)
        ? capitalizeFirstLetter(words[i])
        : words[i];
    }
  }

  return words.join(" ");
};

const nameRegex: Parameters<typeof ZodString.prototype.regex> = [
  /^[A-Za-z ]*$/,
  "Field can only include a-z and spaces",
];
const spacesInSequence: Parameters<typeof ZodString.prototype.regex> = [
  /^(?:(?![ ]{2}).)+$/gm,
  "Field cannot contain two spaces in a row",
];

const isUpperCase: Parameters<typeof ZodString.prototype.refine> = [
  (x) => x.toUpperCase() === x,
  "String must be uppercase",
];
const isTitleCase: Parameters<typeof ZodString.prototype.refine> = [
  (x) => toTitleCase(x) === x,
  'String must be title-cased (E.g. "Elon Mattias" instead of "elon mattias" or "ELON mattias")',
];

const lastNameRegex: Parameters<typeof ZodString.prototype.regex> = [
  /^(\.|[A-Za-z ]*)$/,
  "Field can either contain a `.` or a-z and spaces (for example: `.` or `Johan Andersson`)",
];
const matchMoveFieldRegex = [
  /^[A-Za-z0-9 ]*$/,
  { message: "Field can only include a-z, 0-9 and spaces" },
] as const;
export const FirstNameInput = z
  .string()
  .min(1)
  .max(50)
  .regex(...nameRegex)
  .regex(...spacesInSequence)
  .refine(...isTitleCase)
  .refine(...disallowLeadingAndTrailingSpaces);
export const MiddleNameInput = z
  .string()
  .min(1)
  .max(50)
  .regex(...nameRegex)
  .regex(...spacesInSequence)
  .refine(...disallowLeadingAndTrailingSpaces);
export const LastNameInput = z
  .string()
  .min(1)
  .max(50)
  .regex(...lastNameRegex)
  .regex(...spacesInSequence)
  .refine(...isUpperCase)
  .refine(...disallowLeadingAndTrailingSpaces);
export const PreferredNameInput = z
  .string()
  .min(1)
  .max(21)
  .regex(...nameRegex)
  .regex(...spacesInSequence)
  .refine(...disallowLeadingAndTrailingSpaces);

export const ZipCodeInput = z
  .string()
  .max(12)
  .min(3)
  .regex(
    /^[A-Z0-9 -]{3,12}$/,
    "Field must only contain A-Z, 0-9, spaces, and `-`"
  );

export const AddressLineInput = z
  .string()
  .max(35)
  .regex(...matchMoveFieldRegex)
  .regex(...spacesInSequence)
  .refine(...disallowLeadingAndTrailingSpaces);
export const CityInput = z
  .string()
  .max(20)
  .regex(...matchMoveFieldRegex)
  .regex(...spacesInSequence)
  .refine(...disallowLeadingAndTrailingSpaces);
export const StateInput = z
  .string()
  .max(20)
  .regex(...matchMoveFieldRegex)
  .regex(...spacesInSequence)
  .refine(...disallowLeadingAndTrailingSpaces);
export const CountryInput = z
  .string()
  .max(50)
  .regex(...matchMoveFieldRegex)
  .regex(...spacesInSequence)
  .refine(...disallowLeadingAndTrailingSpaces);
export const CountryCodeInput = z
  .string()
  .max(3)
  .regex(...matchMoveFieldRegex)
  .regex(...spacesInSequence)
  .refine(...disallowLeadingAndTrailingSpaces);

const User = z
  .object({
    title: z.string(), // dropdown - no need stringent validation
    first_name: FirstNameInput,
    last_name: LastNameInput,
    preferred_name: PreferredNameInput,
    gender: z.string(), // dropdown - no need stringent validation
    nationality: z.string(), // dropdown - no need stringent validation
    date_of_birth: z
      .unknown()
      .transform((date: unknown, ctx) => {
        if (dayjs.isDayjs(date) && date.isValid()) return date;
        ctx.addIssue({ code: z.ZodIssueCode.custom, message: "Not a date" });
        return z.NEVER;
      })
      .refine(
        (d) => "isAfter" in d && d.isAfter("1920", "year"),
        "Must be after 1920"
      )
      .refine(
        (d) => "isBefore" in d && d.isBefore(new Date(), "year"),
        "Must be in the past (before this year)"
      ),
    identification_no: z
      .string()
      .regex(/^[A-Z0-9]*$/, "Must be A-Z (capitalized), 0-9 and no spaces"),
    identification_type: z.string(), // dropdown - no need stringent validation
    country_of_issue: z.string(), // dropdown - no need stringent validation
    email: z
      .string()
      .email()
      .refine((str) => str.toLowerCase() === str, "Must be lowercase"),
    phone_code: PhoneNumberCountryCodeInput,
    phone: PhoneNumberLocalInput,
    billing_delivery_address1: AddressLineInput.refine(...isTitleCase),
    billing_delivery_address2: AddressLineInput.refine(...isTitleCase),
    billing_delivery_city: CityInput.refine(...isTitleCase),
    billing_delivery_zip_code: ZipCodeInput.regex(...spacesInSequence).refine(
      ...disallowLeadingAndTrailingSpaces
    ),
    billing_delivery_state: StateInput.refine(...isTitleCase),
    billing_delivery_country: z.string(), // dropdown - no need stringent validation
  })
  .refine(
    ({ phone_code, phone }) => {
      const fullNumber = formatPhoneNumber(phone_code, phone);
      return (
        isValidPhoneNumber(fullNumber) &&
        parsePhoneNumber(fullNumber).number === fullNumber
      );
    },
    { path: ["phone"], message: "Invalid phone number" }
  );

const UserForm = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const { auth } = useSelector((state: IReduxState) => state);
  const userFormRef = useRef<FormikProps<IWalletHolderProps>>(null);
  const dispatch = useDispatch();
  const [nationalities, setNationalities] = useState<ICountryProps[] | []>([]);
  const [countries, setCountries] = useState<
    { label: string; value: string }[] | []
  >([]);
  const [phoneCodes, setPhoneCodes] = useState<
    { label: string; value: string; iso_alpha2: string }[] | []
  >([]);
  const [idTypes, setIdTypes] = useState<any>([]);
  const [data, setData] = useState<IWalletHolderProps>(wallerHolderInit);
  const [user, setUser] = useState<IWalletHolderProps>(wallerHolderInit);
  // const [editable, setEditable] = useState(false);
  // const kycModeCannotEdit = [KYC_STATUS.SUBMITTED.code, KYC_STATUS.APPROVED.code, KYC_STATUS.VERIFYING.code];

  const fetchDetail = async (id: string) => {
    getWalletHolderDetail(id).then((resp) => {
      setData({
        ...resp,
      });
    });
  };
  useEffect(() => {
    if (data && countries && nationalities && idTypes) {
      setUser({
        ...data,
      });
      // if (data.kyc_mode && !kycModeCannotEdit.includes(data.kyc_mode)) {
      //   setEditable(true);
      // } else {
      //   setEditable(false);
      // }
    }
  }, [data, countries, nationalities, idTypes]);

  useEffect(() => {
    if (params.id) {
      fetchDetail(params.id);
    }
    getCountries().then((res) => {
      setCountries(
        res.map((n: ICountryProps) => ({
          label: n.description,
          value: n.code,
        }))
      );
      getPhoneCodes().then((pc) => {
        setPhoneCodes(
          pc.map((n: ICountryProps) => {
            const country = res.find(
              (item: ICountryProps) => item.code === n.code
            );
            return {
              label: `${country ? country.description : ""} (${n.description})`,
              value: `${n.description}`,
            };
          })
        );
      });
    });
    getNationalities().then((res) => {
      const uniqueNationalities: any = uniqueArray(res, "code");
      setNationalities(
        uniqueNationalities.map((n: { code: string; description: string }) => ({
          label: n.code,
          value: n.code,
          description: n.description,
        }))
      );
    });
    getIdTypes().then((res) => {
      setIdTypes(
        res.map((n: IIdentificationTypeProps) => ({
          label: n.description.display_name,
          value: n.code,
        }))
      );
    });
  }, []);

  const submit = (
    values: IWalletHolderProps,
    { setSubmitting }: FormikHelpers<IWalletHolderProps>
  ) => {
    const changedProperty: any = getChangedValues(values, data);
    if (Object.keys(changedProperty).length === 0) {
      navigate(`/wallet-holder/show/${data.id}`);
    } else {
      if (changedProperty.date_of_birth) {
        changedProperty.date_of_birth = dayjs(
          changedProperty.date_of_birth
        ).format("YYYY-MM-DD");
      }
      if (params.id) {
        updateUser(changedProperty, data.id)
          .then(() => {
            dispatch(
              showToast({
                type: "success",
                title: "User has been updated successfully!",
              })
            );
            navigate(`/wallet-holder/show/${data.id}`);
          })
          .catch((err: AxiosError<CustomError>) => {
            if (err.response?.status !== 401) {
              dispatch(
                showToast({
                  type: "error",
                  title:
                    err.response?.data.error.message ?? "Can not update user.",
                })
              );
            }
            setSubmitting(false);
          });
      } else {
        addUser(changedProperty)
          .then(() => {
            dispatch(
              showToast({
                type: "success",
                title: "User has been added successfully!",
              })
            );
            navigate("/");
          })
          .catch((err: AxiosError<any>) => {
            if (err.response?.status !== 401) {
              setSubmitting(false);
              let message = "";
              if (err.response?.data.error?.cause?.issues) {
                err.response?.data.error?.cause?.issues.forEach((i: any) => {
                  message += `${i.path.toString()}: ${i.message} \n`;
                });
              } else if (err.response?.data.error.message) {
                message = err.response?.data.error.message;
              } else {
                message = "Can not add user.";
              }
              dispatch(
                showToast({
                  type: "error",
                  title: message,
                })
              );
            }
          });
      }
    }
  };

  const renderBtnLabel = (isSubmitting: boolean) => {
    if (isSubmitting) {
      return "Submitting";
    }
    if (params.id) {
      return "Save changes";
    }
    return "Add user";
  };

  return (
    <Box>
      <Box>
        <Link to="/" style={{ fontSize: "14px" }}>
          {t("user_breadcrumb")}
        </Link>
        <ArrowForwardIosIcon sx={{ fontSize: 10, marginLeft: 1 }} />
      </Box>
      <Box sx={{ typography: "h1", margin: "15px 0 25px" }}>
        {params.id ? "Edit User" : t("add_wallet_holder_page_title")}
      </Box>
      <Card>
        <CardContent sx={{ margin: ["10px", "10px", "20px"] }}>
          <Formik
            innerRef={userFormRef}
            initialValues={user}
            onSubmit={submit}
            validate={validate}
            enableReinitialize
            validateOnMount={true}
          >
            {({
              handleSubmit,
              isSubmitting,
              isValid,
              values,
              setFieldValue,
            }) => {
              useEffect(() => {
                const generateName = () =>
                  [firstNameParts.join(" "), lastNameParts.join(" ")].join(" ");
                const isWithinLimit = () =>
                  [firstNameParts.join(" "), lastNameParts.join(" ")].join(" ")
                    .length <= 21;
                const firstNameParts = values.first_name.split(" ") ?? [];
                const lastNameParts = values.last_name.split(" ") ?? [];
                if (lastNameParts.length === 1 && lastNameParts[0] === ".")
                  lastNameParts.pop();
                while (!isWithinLimit()) {
                  if (firstNameParts.length > 1) firstNameParts.pop();
                  else if (lastNameParts.length > 1) lastNameParts.shift();
                  else if (
                    firstNameParts.length === 1 &&
                    firstNameParts[0].length !== 1
                  )
                    firstNameParts[0] = firstNameParts[0][0];
                  else if (
                    firstNameParts.length === 1 &&
                    firstNameParts[0].length === 1
                  )
                    firstNameParts.pop();
                  else break;
                }
                setFieldValue("preferred_name", generateName().slice(0, 22));
              }, [values.first_name, values.last_name]);
              return (
                <form onSubmit={handleSubmit}>
                  <Stack
                    gap="20px"
                    sx={{
                      width: ["100%", "100%", "50%", "40%"],
                    }}
                  >
                    <Typography variant="h2" sx={{ marginTop: 4 }}>
                      Personal Information
                    </Typography>
                    <SeaSelect
                      name="title"
                      label={t("title")}
                      fullWidth
                      options={TITLE}
                    />
                    <SeaInput
                      name="first_name"
                      helpText="Max 50 chars. Title-cased. A-Z and spaces."
                      maxLength={50}
                      subText="Name must be written exactly as it is in passport"
                      label="First / Given name"
                      fullWidth
                    />
                    <SeaInput
                      name="last_name"
                      helpText="Max 50 chars. Upper-cased. A-Z and spaces."
                      subText="Name must be written exactly as it is in passport"
                      label="Last / Family name"
                      fullWidth
                    />
                    <SeaInput
                      name="preferred_name"
                      subText="This name is printed on the user's card and is also used inside SeaBoard to identify the user"
                      label="Preferred name"
                      fullWidth
                      maxLength={21}
                      helpText="Max 21 chars. A-Z and spaces."
                    />
                    <SeaSelect
                      name="gender"
                      label={t("gender")}
                      fullWidth
                      options={GENDER}
                    />
                    <SeaAutocomplete
                      options={nationalities}
                      label={t("nationality")}
                      name="nationality"
                      renderOption={(
                        props: JSX.IntrinsicAttributes &
                          ClassAttributes<HTMLLIElement> &
                          LiHTMLAttributes<HTMLLIElement>,
                        option: { [x: string]: any; id: Key | null | undefined }
                      ) => {
                        return (
                          <li
                            {...props}
                            key={option.code}
                          >{`${option.description.replace(
                            "Nationality for",
                            ""
                          )}: ${option.value}`}</li>
                        );
                      }}
                    />
                    <SeaDatepicker
                      name="date_of_birth"
                      label={t("date_of_birth")}
                      fullWidth
                    />
                    <SeaAutocomplete
                      options={idTypes}
                      name="identification_type"
                      label={t("identification_type")}
                      fullWidth
                    />
                    <SeaInput
                      name="identification_no"
                      helpText="A-Z, 0-9, no spaces."
                      label={t("identification_no")}
                      fullWidth
                    />
                    <SeaAutocomplete
                      options={countries}
                      name="country_of_issue"
                      label={t("country_of_issue")}
                      fullWidth
                    />
                    <Typography variant="h2" sx={{ marginTop: 4 }}>
                      {t("contact_info")}
                    </Typography>
                    <SeaInput
                      name="email"
                      label={t("email")}
                      subText="Used to log into SeaBoard and to receive account-related information"
                      fullWidth
                      disabled={
                        !!(auth.user?.Role !== IRoles.SUPERADMIN && params.id)
                      }
                    />
                    <FormControl>
                      <FormLabel
                        sx={{
                          color: colors.Black,
                          fontWeight: 500,
                          fontSize: "15px",
                          marginBottom: "8px",
                        }}
                      >
                        Mobile phone number
                      </FormLabel>
                      <Box sx={{ display: "flex", flex: 1 }}>
                        <Box
                          sx={{ width: ["150px", "150px", "200px", "200px"] }}
                        >
                          <SeaAutocomplete
                            options={phoneCodes}
                            name="phone_code"
                            fullWidth
                            noBorder={true}
                            display={(d: any) => `${d.label}(${d.value}) `}
                          />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <SeaInput name="phone" fullWidth noBorder={true} />
                        </Box>
                      </Box>
                    </FormControl>
                    <Typography variant="h2" sx={{ marginTop: 4 }}>
                      Residential address
                    </Typography>
                    <SeaInput
                      helpText="Max 35 chars. Title-cased. A-Z, 0-9, and spaces."
                      name="billing_delivery_address1"
                      maxLength={35}
                      label={t("billing_delivery_address1")}
                      fullWidth
                    />
                    <SeaInput
                      helpText="Max 35 chars. Title-cased. A-Z, 0-9, and spaces."
                      name="billing_delivery_address2"
                      maxLength={35}
                      label={t("billing_delivery_address2")}
                      fullWidth
                    />
                    <SeaInput
                      helpText="Max 20 chars. Title-cased. A-Z, 0-9, and spaces."
                      name="billing_delivery_city"
                      maxLength={20}
                      label={t("billing_delivery_city")}
                      fullWidth
                    />
                    <SeaInput
                      helpText="Max 20 chars. Title-cased. A-Z, 0-9, and spaces."
                      name="billing_delivery_state"
                      maxLength={20}
                      label={t("billing_delivery_state")}
                      fullWidth
                    />
                    <SeaInput
                      helpText={"Max 12 chars. A-Z, 0-9, spaces, and `-`"}
                      name="billing_delivery_zip_code"
                      label={t("billing_delivery_zip_code")}
                      fullWidth
                    />
                    <SeaAutocomplete
                      options={countries}
                      name="billing_delivery_country"
                      label="Country"
                      fullWidth
                    />
                    <Button
                      variant="contained"
                      sx={{ margin: "20px 0" }}
                      type="submit"
                      disabled={isSubmitting || !isValid}
                    >
                      {renderBtnLabel(isSubmitting)}
                    </Button>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

const validate = async (values: IWalletHolderProps) => {
  const result = await User.safeParseAsync(values);
  return result.success ? {} : createValidationResult(result.error);
};

export default UserForm;
