import { Box } from "@mui/material";
import { useEffect, useState } from "react";

type CountDownProp = {
  seconds: number;
  onFinish: () => void;
};

const CountDown = ({ seconds, onFinish }: CountDownProp) => {
  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  useEffect(() => {
    setTimeLeft(seconds);
  }, [seconds]);

  useEffect(() => {
    if (timeLeft === null) {
      return;
    }

    if (timeLeft === 0) {
      onFinish();
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return <Box>{`  (${timeLeft} seconds)`}</Box>;
};

export default CountDown;
