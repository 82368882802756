import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";
import authReducer from "./auth/reducer";
import authSaga from "./auth/saga";
import toastReducer from "./toast/reducer";

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([fork(authSaga)]);
}

const reducers = combineReducers({
  auth: authReducer,
  toast: toastReducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (gDM) => gDM({ serializableCheck: false }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
