import {
  Box,
  Chip,
  FormControl,
  FormLabel,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import SeaAutocomplete from "components/form/SeaAutocomplete";
import SeaInput from "components/form/SeaInput";
import colors from "constants/colors";
import { useFormikContext } from "formik";
import { IBankAccountProps } from "interfaces/bank-account";
import { ITransactionProps } from "interfaces/transaction";
import { TransferProvider } from "pages/users/transfer";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatMoney, formatMoneyWithDecimal } from "utils/formatMoney";
import trackPathForAnalytics from "utils/trackPageForAnalytics";
import { t } from "utils/translate";

function getCurrentDate(separator = "") {
  const newDate = new Date();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}`;
}

const TransactionForm = () => {
  const { data, bankAccounts, activeAccount, setActiveAccount } =
    useContext(TransferProvider);
  const [currencies, setCurrencies] = useState<string[] | []>([]);
  const { values } = useFormikContext<ITransactionProps>();
  const { pathname } = useLocation();
  const { setValues } = useFormikContext<any>();
  const [comment, setComment] = useState<string>("");
  const suggestions = [getCurrentDate("-") + " allotment", "Fee refund"].map(
    (option) => {
      const c = option == comment ? "primary" : "default";
      return (
        <Chip
          sx={{ mr: "8px", mt: "4px", mb: "4px" }}
          size="small"
          key={option}
          label={option}
          variant="outlined"
          onClick={() => setComment(option)}
          color={c}
        />
      );
    }
  );

  useEffect(() => {
    trackPathForAnalytics({
      path: pathname,
      search: "",
      title: "Transfer: Set Detail",
    });
  }, []);

  useEffect(() => {
    const mappingAccount = bankAccounts.find(
      (item) => item.currency === values.currency_code
    );

    if (mappingAccount) {
      setActiveAccount(mappingAccount);
    }
  }, [values.currency_code]);

  useEffect(() => {
    setCurrencies(
      bankAccounts.map(({ currency }: IBankAccountProps) => currency)
    );
  }, [bankAccounts]);

  useEffect(() => {
    console.log("setting comment value" + { comment });
    setValues({
      ...values,
      ["comments"]: comment,
    });
  }, [comment]);

  const renderCurrenyOptions = (props: any, option: any) => {
    return (
      <Box
        component="li"
        sx={{ "& > img": { mr: 1, flexShrink: 0 } }}
        {...props}
      >
        <img
          src={`${process.env.PUBLIC_URL}/countries/${option.value?.toLowerCase()}.png`}
          alt={option.value}
          width="20px"
          height="20px"
        />
        {option.label}
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Box sx={{ typography: "h1" }}>{t("transfer")}</Box>
        <FormLabel>
          {t("to")}{" "}
          <span
            style={{ color: "#5D31FF" }}
          >{`${data.first_name} ${data.last_name}`}</span>
          .
        </FormLabel>
      </Box>
      <Box>
        <Box mb={1}>
          <InputLabel sx={{ fontSize: "16px", marginBottom: 0 }}>
            {t("you_send")}
          </InputLabel>
          <FormLabel>
            {t("your_balance")}:{" "}
            <Typography
              display="inline"
              color={colors.Primary500}
              fontWeight={600}
            >
              {activeAccount
                ? `${formatMoneyWithDecimal(
                    formatMoney(activeAccount?.balance)
                  )} ${activeAccount?.currency} `
                : "..."}
            </Typography>
          </FormLabel>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", flex: 1 }}>
            <Box sx={{ width: "120px" }}>
              <SeaAutocomplete
                options={currencies.map((item) => ({
                  label: item,
                  value: item,
                }))}
                name="currency_code"
                fullWidth
                noBorder={true}
                hideClear
                renderOption={renderCurrenyOptions}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/countries/${values.currency_code.toLowerCase()}.png`}
                        alt={values.currency_code}
                        width="20px"
                        height="20px"
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <SeaInput
                name="amount"
                fullWidth
                noBorder={true}
                mode="currency"
              />
            </Box>
          </Box>
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontSize: "14px", color: "#555566" }}>
            {t("our_fee")}
          </Typography>
        </Box>
        <Box color="#111133" fontSize="14px">
          Free
        </Box>
      </Box>
      <Box mb={2}>
        <InputLabel sx={{ fontSize: "16px", marginBottom: 1 }}>
          {t("they_receives")}
        </InputLabel>
        <FormControl sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", flex: 1 }}>
            <Box sx={{ width: "120px" }}>
              <SeaAutocomplete
                options={currencies.map((item) => ({
                  label: item,
                  value: item,
                }))}
                name="currency_code"
                fullWidth
                noBorder={true}
                hideClear
                renderOption={renderCurrenyOptions}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/countries/${values.currency_code.toLowerCase()}.png`}
                        alt={values.currency_code}
                        width="20px"
                        height="20px"
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <SeaInput
                name="amount"
                fullWidth
                noBorder={true}
                mode="currency"
              />
            </Box>
          </Box>
        </FormControl>
      </Box>
      <Box mb={1}>
        <InputLabel sx={{ fontSize: "16px", marginBottom: 1 }}>
          {t("comments_optional")}
        </InputLabel>
        <SeaInput name="comments" fullWidth />
        <Typography sx={{ fontSize: "14px", color: "#555566", mt: "8px" }}>
          {t("suggestions") + ":"}
        </Typography>
        <Box
          sx={{
            flexWrap: "wrap",
          }}
        >
          {suggestions}
        </Box>
      </Box>
    </>
  );
};

export default TransactionForm;
