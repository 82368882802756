import { Typography } from "@mui/material";
import colors from "constants/colors";

export const formatMoney = (value: string | number) => {
  const num = value.toString().replace(/,/gi, "");
  const splitDecima = num.toString().split(".");
  const isNegative = +splitDecima[0] < 0;
  let num2;
  if (splitDecima.includes("-")) {
    num2 = `-${splitDecima[0]
      .replace("-", "")
      .split(/(?=(?:\d{3})+$)/)
      .join(",")}`;
  } else {
    num2 = splitDecima[0]
      .replace("-", "")
      .split(/(?=(?:\d{3})+$)/)
      .join(",");
  }

  const res = splitDecima.length > 1 ? `${num2}.${splitDecima[1]}` : `${num2}`;
  return isNegative ? `-${res}` : res;
};

export const formatMoneyWithDecimal = (value: any) => {
  const money = value.toString().split(".");
  if (money.length === 1) {
    return `${value}.00`;
  }
  if (money[1].length === 1) {
    return `${money[0]}.${money[1]}0`;
  }
  return value;
};

export const renderMoney = (
  amount: number,
  currency?: string,
  format?: "h1",
  fontSize?: number
) => {
  const posAmt = formatMoneyWithDecimal(
    formatMoney(amount.toString().replace("-", ""))
  );
  return (
    <Typography
      variant={format ?? undefined}
      sx={{ color: colors.Error500, fontSize: fontSize ?? 16 }}
    >
      -{posAmt} {currency}
    </Typography>
  );
};
