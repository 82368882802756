export interface IBankAccountProps {
  balance: string;
  currency: string;
  currency_name: string;
}

export const initBankAccount = {
  balance: "",
  currency: "SGD",
  currency_name: "SGD",
};
