import { FormLabelProps, InputLabelProps } from "@mui/material";
import colors from "constants/colors";

export const setFormStyles = () => ({
  MuiFormHelperText: {
    styleOverrides: {
      root: () => ({
        fontSize: 12,
        color: colors.Error500,
        marginLeft: 0,
      }),
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: () => ({
        padding: "8.5px",
      }),
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: () => ({
        "& .MuiInputBase-input": {
          borderRadius: "8px",
          position: "relative",
          fontSize: 16,
          height: "20px",
          padding: "10px 12px",
          "&:focus": {
            boxShadow: "none",
            borderColor: colors.Black,
          },
        },
        "&.MuiInputNoBorder .MuiOutlinedInput-notchedOutline": {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      }),
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ ownerState }: { ownerState: FormLabelProps }) => ({
        ...ownerState,
        fontSize: "14px",
        fontWeight: 500,
      }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ ownerState }: { ownerState: InputLabelProps }) => ({
        ...ownerState,
        color: colors.Black,
        fontSize: "20px",
        fontWeight: 500,
        overflow: "visible",
      }),
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: () => ({
        "&.MuiError .MuiOutlinedInput-notchedOutline": {
          borderColor: "#d32f2f",
        },
        "& .MuiInputBase-root": {
          padding: "2px 5px 2.1px",
        },
        "& .MuiAutocomplete-inputRoot .MuiInputBase-input": {
          paddingTop: "8.5px",
        },
        "&.MuiInputNoBorder .MuiOutlinedInput-notchedOutline": {
          borderRight: "none",
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
      }),
      popper: () => ({
        "& .MuiAutocomplete-option": {
          fontSize: "14px",
        },
      }),
    },
  },
});
