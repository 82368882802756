import { Box, InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import enGBLocale from "date-fns/locale/en-GB";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

interface ISeaDatepickerProp {
  label: string;
  placeholder?: string;
  name: string;
  fullWidth?: boolean;
}

const SeaDatepicker = ({ label, name }: ISeaDatepickerProp) => {
  const { values, touched, errors, setValues, setFieldTouched } =
    useFormikContext<any>();
  const error: any = errors[name];
  const isError = touched[name] && error?.length > 0;
  const [locale, setLocale] = useState(enGBLocale);
  const getNavigatorLanguage = () =>
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : "en";

  useEffect(() => {
    const browserLocale = getNavigatorLanguage();

    const importLocaleFile = async () => {
      const localeToSet = await import(
        `date-fns/locale/${browserLocale}/index.js`
      );
      setLocale(localeToSet.default);
    };
    if (locale.code !== browserLocale) {
      importLocaleFile();
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <Box>
        <InputLabel
          shrink
          error={touched[name] && Boolean(errors[name])}
          sx={isError ? { color: "#d32f2f" } : {}}
        >
          {label}
        </InputLabel>
        <DesktopDatePicker
          value={dayjs(values[name] ?? "")}
          onChange={(e) => {
            if (e) {
              setValues({
                ...values,
                [name]: dayjs(e),
              });
            } else {
              setValues({
                ...values,
                [name]: null,
              });
            }
          }}
          className="MuiDatePicker"
          renderInput={(p: any) => (
            <TextField
              {...p}
              fullWidth
              error={isError}
              helperText={isError && error}
              onBlur={() => setFieldTouched(name, true, true)}
            />
          )}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default SeaDatepicker;
