import { InfoOutlined } from "@mui/icons-material";
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import colors from "constants/colors";
import { useContext } from "react";
import { t } from "utils/translate";
import { BulkTransferProvider } from "./BulkTransfer";

interface CSVInfoReportProps {
  info: string[]; // Define the type of info more specifically if possible
  csv_file_link: string;
}
const CSVInfoReport = ({ info, csv_file_link }: CSVInfoReportProps) => {
  if (info.length === 0) return null;

  const contextData = useContext(BulkTransferProvider);

  const isError = contextData.jobId === "validation_falied";

  const getReportName = () => {
    const jobData = contextData.jobInfo;
    if (jobData.status === "disbursal_complete") {
      return t("download_success_disbursal_report");
    } else if (jobData.status === "validation_sucess") {
      return t("download_success_report");
    } else {
      return "Download Report";
    }
  };

  // const getReportName = () => {
  //   return isError ? t("download_error_report") : t("download_success_report");
  // };

  return (
    <Card
      sx={{
        p: 2,
        width: { xs: "100%", sm: "auto" },
        display: "flex",
        flexDirection: { xs: "column", sm: "column" },
        alignItems: "start",
        gap: 1,
        ml: 1,
        backgroundColor: colors.SuccessLight,
      }}
    >
      <Stack alignItems="start" direction="row" gap={1}>
        <InfoOutlined
          sx={{ color: isError ? colors.Error800 : colors.SuccessDark }}
        />
        <Stack alignItems="start" direction="column" gap={1}>
          <Typography
            variant="body1"
            sx={{ fontWeight: 500, fontSize: 16, color: colors.Error800 }}
          >
            {t("csv_has_info")}
          </Typography>
          <Box>
            {info.map((element) => {
              return (
                <Stack alignItems="start" direction="row" key={element[0]}>
                  <Typography
                    sx={{ px: 1 }}
                    variant="body1"
                  >{`\u2022`}</Typography>
                  <Typography variant="body1">
                    {`${element as string}`}
                  </Typography>
                </Stack>
              );
            })}
          </Box>
          <Button
            sx={{
              border: "1px solid #CCCCDD",
              color: "#111133",
              px: 2,
              my: 1,
              fontWeight: 500,
            }}
            onClick={() => {
              const link = document.createElement("a");
              link.href = csv_file_link;
              link.download = "BulkTransferReport.csv";
              link.click();
            }}
            variant="outlined"
          >
            {getReportName()}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default CSVInfoReport;
