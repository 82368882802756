import {
  USER_CHECK,
  USER_CHECK_ERROR,
  USER_CHECK_SUCCESS,
  USER_LOGIN,
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_LOGOUT_ERROR,
  USER_LOGOUT_SUCCESS,
  USER_REFRESH_TOKEN,
  USER_REFRESH_TOKEN_ERROR,
  USER_REFRESH_TOKEN_SUCCESS,
} from "./types";

export const userLogin = (payload: {
  email: string;
  password: string;
  deviceType: string;
  appVersion: string;
}) => ({
  type: USER_LOGIN,
  payload,
  meta: {
    success: USER_LOGIN_SUCCESS,
    failure: USER_LOGIN_ERROR,
  },
});

export const userCheck = () => ({
  type: USER_CHECK,
  meta: {
    success: USER_CHECK_SUCCESS,
    failure: USER_CHECK_ERROR,
  },
});

export const userRefreshToken = (payload: { token: string }) => {
  return {
    type: USER_REFRESH_TOKEN,
    payload,
    meta: {
      success: USER_REFRESH_TOKEN_SUCCESS,
      failure: USER_REFRESH_TOKEN_ERROR,
    },
  };
};

export const userLogout = () => ({
  type: USER_LOGOUT,
  meta: {
    success: USER_LOGOUT_SUCCESS,
    failure: USER_LOGOUT_ERROR,
  },
});
