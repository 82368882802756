import { Box, Card } from "@mui/material";
import colors from "constants/colors";
import { useEffect, useState } from "react";
import { verifyEmail } from "utils/apiProvider";
import { useQuery } from "utils/useQuery";

const EmailConfirmation = () => {
  const query = useQuery();
  const [status, setStatus] = useState<any>(false);
  const handleVerifyEmail = async () => {
    const token = query.get("token");
    if (token) {
      verifyEmail(token)
        .then((res) => {
          if (res.status === 200) setStatus(true);
        })
        .catch(() => {
          setStatus({ error: true });
        });
    } else {
      setStatus({
        error: true,
      });
    }
  };

  useEffect(() => {
    handleVerifyEmail();
  }, []);

  const renderMessage = () => {
    if (!status) {
      return <Box>Loading...</Box>;
    }

    if (status.error) {
      return (
        <Box color={colors.Error500}>
          Can not update email since there is wrong token.
        </Box>
      );
    }

    return (
      <>
        <Box textAlign="center" py={4}>
          <img
            src={`${process.env.PUBLIC_URL}/images/Icon.png`}
            alt="success"
            width="80px"
          />
        </Box>
        <Box sx={{ fontSize: "24px", fontWeight: 600 }}>Email Updated</Box>
        <Box pb={4}>You have successfully updated your email address.</Box>
      </>
    );
  };

  return (
    <Box display="flex" sx={{ justifyContent: "center" }}>
      <Card sx={{ width: "400px", p: 3, textAlign: "center" }}>
        {renderMessage()}
      </Card>
    </Box>
  );
};

export default EmailConfirmation;
