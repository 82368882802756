import { Box, Card, CardContent, Stack, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import SeaInput from "components/form/SeaInput";
import colors from "constants/colors";
import { Formik, FormikProps } from "formik";
import { IReduxState } from "interfaces/redux";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogin } from "redux/auth/action";
import { showToast } from "redux/toast/action";
import { isEmail } from "utils/regex";
import { APP_VERSION } from "utils/version";

export interface ILoginForm {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useSelector((state: IReduxState) => state);
  const loginFormRef = useRef<FormikProps<ILoginForm>>(null);

  const { userAgent } = window.navigator;
  useEffect(() => {
    if (auth.error) {
      dispatch(
        showToast({
          type: "error",
          title: `Your email and password do not match.`,
        })
      );
      loginFormRef.current?.setSubmitting(false);
    }
  }, [auth]);

  const submit = (values: ILoginForm) => {
    dispatch(
      userLogin({
        ...values,
        deviceType: "browser",
        appVersion: userAgent,
      })
    );
  };

  useEffect(() => {
    if (auth.user) {
      navigate("/");
    }
  }, [auth.user]);

  const matches = useMediaQuery("(min-width:600px)");

  return (
    <Box>
      {/* <Button onClick={handleForgotPassword}>Forgot Password</Button> */}
      <Box mb={4} ml={2}>
        <Box sx={{ typography: "h1", color: colors.White, lineHeight: 2 }}>
          Open <span style={{ color: colors.Yellow }}>Maritime</span> Banking
        </Box>
        <Box sx={{ typography: "h3", color: colors.White }}>
          Log in to your account
        </Box>
      </Box>
      <Card variant="outlined" sx={{ width: matches ? 400 : 300 }}>
        <CardContent>
          <Formik
            validateOnMount={true}
            innerRef={loginFormRef}
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={submit}
            validate={(values: ILoginForm) => {
              const errors: any = {};
              if (!values.email || !isEmail(values.email)) {
                errors.email = "Please enter a valid email address.";
              }
              if (!values.password) {
                errors.password = "Please enter a password.";
              }
              return errors;
            }}
          >
            {({ handleSubmit, isSubmitting, isValid }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Stack gap={3}>
                    <SeaInput name="email" label="Email" fullWidth />
                    <SeaInput
                      name="password"
                      label="Password"
                      fullWidth
                      type="password"
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={isSubmitting || !isValid}
                    >
                      {isSubmitting ? "Submitting" : "Log in"}
                    </Button>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
      <Box color="#fff" textAlign="center" my={1}>
        v{APP_VERSION}
      </Box>
    </Box>
  );
};

export default LoginPage;
