import { Box, Card, CardContent } from "@mui/material";
import WalletHolderTabMenu from "components/users/Menu";
import UserProfile from "components/users/UserProfile";
import WhDetailHeader from "components/wallet-holder/detail/WhDetailHeader";
import { ICountryProps } from "interfaces/common";
import { IWalletHolderProps, wallerHolderInit } from "interfaces/wallet-holder";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { showToast } from "redux/toast/action";
import { getCountries, getWalletHolderDetail } from "utils/apiProvider";

const WalletHolderDetail = () => {
  const params = useParams<{ id: string }>();
  const [data, setData] = useState<IWalletHolderProps>(wallerHolderInit);
  const [countries, setCountries] = useState<ICountryProps[]>([]);
  const dispatch = useDispatch();

  const fetchDetail = async (id: string) => {
    getWalletHolderDetail(id)
      .then((resp) => {
        setData(resp);
      })
      .catch((err) => {
        if (err.response?.status !== 401) {
          dispatch(
            showToast({
              type: "error",
              title: "Can not fetch the user",
            })
          );
        }
      });
  };

  useEffect(() => {
    getCountries().then((res) => {
      setCountries(res);
    });
    if (params.id) {
      fetchDetail(params.id);
    }
  }, []);

  const getCountry = (code: string) => {
    return countries.find((item) => item.code === code)?.description ?? "";
  };

  return (
    <Box>
      <WhDetailHeader data={data} id={data.id} />
      <Card>
        <CardContent sx={{ margin: ["10px", "10px", "20px"] }}>
          <WalletHolderTabMenu id={data.id} />
          <UserProfile data={data} getCountry={getCountry} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default WalletHolderDetail;
