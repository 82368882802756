import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, InputAdornment, Modal, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import SeaInput from 'components/form/SeaInput';
import { Formik } from 'formik';
import { IWalletHolderProps } from 'interfaces/wallet-holder';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showToast } from 'redux/toast/action';
import { style } from 'themes/modal';
import { createPhysicalCard } from 'utils/apiProvider';

const AssignCardModal = ({ data, updateRows, show }: { data: IWalletHolderProps; updateRows: any; hideDeco?: boolean; show: boolean }) => {
  const [showAssignCardModal, setShowAssignCardModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => setShowAssignCardModal(show), [show]);

  const submit = (values: { proxyNo: string }) => {
    setIsSubmitting(true);
    createPhysicalCard(data.id, `PY${values.proxyNo}`)
      .then(() => {
        dispatch(
          showToast({
            type: 'success',
            title: 'Card has been assigned successfully!'
          })
        );
        updateRows(data.id, [
          {
            field: 'card_status',
            value: 'ASSIGNED'
          }
        ]);
        setShowAssignCardModal(false);
        setIsSubmitting(false);
      })
      .catch((err: AxiosError<any>) => {
        if (err.response?.status !== 401) {
          const message = err.response?.data.error?.cause?.issues?.[0]?.message ?? err.response?.data.error.message ?? 'Can not assign card';

          dispatch(
            showToast({
              type: 'error',
              title: message
            })
          );
        }
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal
        open={showAssignCardModal}
        onClose={() => {
          setShowAssignCardModal(false);
        }}
      >
        <Box sx={style}>
          <CloseIcon onClick={() => setShowAssignCardModal(false)} sx={{ cursor: 'pointer' }} />
          <Box>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2 }}>
              Assign Card
            </Typography>
            <Typography id="modal-modal-description" sx={{ mb: 3 }}>
              You are assigning card to
              <span style={{ color: '#5D31FF' }}>{` ${data.first_name} ${data.last_name}`}</span>
            </Typography>

            <Formik
              initialValues={{
                proxyNo: ''
              }}
              onSubmit={submit}
              enableReinitialize
              validateOnMount={true}
              validate={(values) => {
                const errors: any = {};
                if (values.proxyNo.match(/^\d{12}$/g) === null) {
                  errors.proxyNo = 'Must be: 12 digits, 0-9, no spaces.';
                }
                return errors;
              }}
            >
              {({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <SeaInput
                    label="Card Proxy Number"
                    name="proxyNo"
                    subText="Back of card between magnetic stripe and glossy symbol."
                    helpText="12 digits. All numbers."
                    maxLength={12}
                    placeholder="000071382186"
                    startAdornment={<InputAdornment position="start">PY</InputAdornment>}
                    fullWidth
                  />
                  <Box display="flex" justifyContent="space-between" my={4}>
                    <Button variant="contained" fullWidth type="submit" disabled={isSubmitting || !values.proxyNo}>
                      Confirm
                    </Button>
                    <Button
                      type="button"
                      fullWidth
                      sx={{
                        border: '1px solid #BEADFF',
                        color: '#381C9F',
                        borderRadius: '100px',
                        fontSize: '14px',
                        padding: '10px 20px',
                        textAlign: 'center',
                        display: 'inline-block',
                        ml: 2,
                        cursor: 'pointer',
                        '&:hover': {
                          background: 'rgba(93, 49, 255, 0.04)'
                        }
                      }}
                      onClick={() => setShowAssignCardModal(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AssignCardModal;
