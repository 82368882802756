/* eslint-disable no-console */
import { GA4React } from "ga-4-react";

const ga4react = new GA4React(
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? ""
).initialize();

export interface AnalyticsData {
  path: string;
  search: string;
  title: string;
}

const trackPathForAnalytics = (data: AnalyticsData) => {
  const { path, search, title } = data;
  console.log(title);

  ga4react
    .then((ga) => {
      ga.pageview(path, search, title);
    })
    .catch((err) => console.error(`Analytics failed`, err));
};

export default trackPathForAnalytics;
