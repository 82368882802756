import { ButtonProps } from "@mui/material";
import colors from "constants/colors";

export const setButtonStyles = () => ({
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState }: { ownerState: ButtonProps }) => ({
        transition: "none",
        "&.Mui-focusVisible": {
          boxShadow: "none",
          background: "#B6CB06",
        },
        ".MuiTouchRipple-root": {
          display: "none",
        },
        ...(ownerState.variant === "outlined" && {
          borderRadius: "50px",
          padding: "2px 10px",
        }),
        ...(ownerState.variant === "text" && {
          padding: 0,
          "&:hover": {
            background: "transparent",
            textDecoration: "underline",
          },
        }),
        ...(ownerState.variant === "contained" &&
          ownerState.color === "primary" && {
            marginTop: 1,
            fontSize: "16px",
            fontWeight: 500,
            backgroundColor: colors.Accent500,
            color: colors.Black,
            height: "45px",
            borderRadius: "50px",
            boxShadow: "none",
            "&:hover": {
              background: colors.Accent700,
              boxShadow: "none",
            },
          }),

      }),
    },
  },
});
