import { SxProps } from "@mui/material";
import colors from "constants/colors";
import { IWalletHolderProps } from "interfaces/wallet-holder";

export type styles = "neutral" | "error" | "success" | "disabled";

export const styleColors: Record<styles, [background: string, text: string]> = {
  disabled: [colors.GreyscaleDarkest, colors.GreyscaleLightest],
  error: [colors.ErrorLightest, colors.ErrorDarkest],
  neutral: [colors.WarningLightest, colors.WarningDarkest],
  success: [colors.SuccessLightest, colors.SuccessDarkest],
};

export const activationStatusColor = (
  status: IWalletHolderProps["activation_status"]
) => {
  if (status === "ARCHIVED") return styleColors.disabled;
  if (status === "ACTIVATED") return styleColors.success;
  return styleColors.neutral;
};

export const verificationStatusColor = (
  status: IWalletHolderProps["verification_status"]
) => {
  const style: styles =
    status === "REJECTED"
      ? "error"
      : status === "APPROVED"
      ? "success"
      : "neutral";
  return styleColors[style];
};

export const riskStatusColor = (status: IWalletHolderProps["risk_status"]) => {
  const style: styles =
    status === "BLOCKED"
      ? "error"
      : status === "CLEARED"
      ? "success"
      : "neutral";
  return styleColors[style];
};

export const cardStatusColor = (cards: IWalletHolderProps["card_statuses"]) => {
  if (!cards) return styleColors.neutral;
  const style: styles = cards.some((card) =>
    ["REQUESTED", "ASSIGNED", "SENT"].includes(card.status)
  )
    ? "neutral"
    : cards.every((card) => card.status === "ACTIVE")
    ? "success"
    : "error";
  return styleColors[style];
};

export const toSX = (color: [background: string, text: string]): SxProps => {
  return {
    background: color[0],
    color: color[1] + " !important",
  };
};

export const toStatusChipProps = (
  color: [background: string, text: string]
): SxProps => {
  return {
    bg: color[0],
    color: color[1],
  };
};
