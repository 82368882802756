import { Box, InputLabel } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useFormikContext } from "formik";
import { ReactElement, useEffect, useState } from "react";

interface ISeaAutocompleteProp {
  label?: string;
  type?: string;
  placeholder?: string;
  name: string;
  fullWidth?: boolean;
  options: any[];
  renderOption?: (props: any, option: any) => ReactElement;
  noBorder?: boolean;
  hideClear?: boolean;
  inputProps?: any;
  display?: any;
}

const SeaAutocomplete = ({
  label,
  name,
  options,
  noBorder,
  hideClear,
  renderOption,
  inputProps,
}: ISeaAutocompleteProp) => {
  const { values, touched, errors, setValues, setFieldTouched } =
    useFormikContext<any>();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    if (values[name]) {
      const selectedValue = options.find((item) => item.value === values[name]);
      if (selectedValue) setDisplayText(selectedValue.label);
    } else if (!values[name] && options.length === 1) {
      setValues({
        ...values,
        [name]: options[0].value,
      });
      setDisplayText(options[0].label);
    }
  }, [values[name], options]);

  return (
    <Box>
      {label && (
        <InputLabel shrink error={touched[name] && Boolean(errors[name])}>
          {label}
        </InputLabel>
      )}
      <Autocomplete
        disablePortal
        id={name}
        renderOption={renderOption}
        onChange={(e: any, value) => {
          let newValue;
          if (typeof value === "string") {
            newValue = value;
          } else if (typeof value === "object" && value?.value) {
            newValue = value.value;
          } else {
            newValue = value;
          }
          setValues({
            ...values,
            [name]: newValue,
          });
        }}
        disableClearable={!matches ? true : hideClear}
        options={options}
        value={displayText}
        renderInput={(params) =>
          inputProps ? (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                ...inputProps,
              }}
              error={touched[name] && Boolean(errors[name])}
              helperText={
                touched[name] && errors[name] ? errors[name]?.toString() : ""
              }
              onBlur={() => {
                setFieldTouched(name, true, true);
              }}
            />
          ) : (
            <TextField
              {...params}
              error={touched[name] && Boolean(errors[name])}
              helperText={
                touched[name] && errors[name] ? errors[name]?.toString() : ""
              }
              onBlur={() => {
                setFieldTouched(name, true, true);
              }}
            />
          )
        }
        className={`${noBorder ? "MuiInputNoBorder" : ""} ${
          errors[name] && touched[name] ? "MuiError" : ""
        }`}
      />
    </Box>
  );
};

export default SeaAutocomplete;
