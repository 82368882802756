export const handleStorage = (name: string, value: any): void => {
  localStorage.setItem(name, JSON.stringify(value));
};

export const deleteItemStorage = (name: string): void => {
  localStorage.removeItem(name);
};

export const clearStorage = (): void => {
  localStorage.clear();
};

export const getStorage = (name: string): any | undefined => {
  if (localStorage.getItem(name))
    return JSON.parse(localStorage.getItem(name) || "") as any;
  return undefined;
};
