/* eslint-disable react/no-unstable-nested-components */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Card,
  CardContent,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { Formik, FormikProps } from "formik";
import { CustomError } from "interfaces/error";
import { createContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toast/action";
import { style } from "themes/modal";
import {
  completeTransactionBulkTransfer,
  requestBulkTransfer,
} from "utils/apiProvider";
import TransactionComplete from "./TransactionComplete";
import TransactionOtpBulk from "./TransactionOtpBulk";

export const TransferProvider = createContext<{
  transactionId: string;
}>({
  transactionId: "",
});

const BulkTransferForm = ({ file, setImporting, setShowError }: any) => {
  const userFormRef = useRef<
    FormikProps<{
      otp_code: string;
    }>
  >(null);
  const [step] = useState(3);
  const [csvDownload, setCsvDownload] = useState("");
  const [total, setTotal] = useState(0);
  const [success, setSuccess] = useState(0);
  const [transactionId, setTransactionId] = useState<string>("");
  const dispatch = useDispatch();

  const requestBulkTransferApi = async () => {
    const formData = new FormData();
    formData.append("file", file);
    requestBulkTransfer(formData)
      .then((resp: any) => {
        if (resp.total === resp.success) {
          setTransactionId(resp.batch_id);
        } else {
          setSuccess(resp.success);
          setTotal(resp.total);
          if (resp.csv_download_url) {
            setCsvDownload(resp.csv_download_url);
            setImporting(false);
            setShowError({
              csv: resp.csv_download_url,
              error: resp.total - resp.success,
            });
          }
        }
        setImporting(false);
      })
      .catch((err) => {
        setImporting(false);
        if (err.response?.status !== 401) {
          dispatch(
            showToast({
              type: "error",
              title: err.response.data.error.message,
            })
          );
        }
      });
  };

  useEffect(() => {
    if (file && file.name) {
      requestBulkTransferApi();
    }
  }, [file]);

  function renderUiByStep() {
    switch (step) {
      case 3:
        return <TransactionOtpBulk transactionId={transactionId} isBulk={true} />;
      case 4:
        return <TransactionComplete />;
      default:
    }
  }

  const contextData = useMemo(() => {
    return { transactionId };
  }, [transactionId]);

  const submit = (values: { otp_code: string }, { setSubmitting }: any) => {
    completeTransactionBulkTransfer(values.otp_code, transactionId)
      .then(() => {
        if (total === success) {
          dispatch(
            showToast({
              type: "success",
              title: "Import transactions successfully!",
            })
          );
        } else {
          dispatch(
            showToast({
              type: "error",
              title: `${success}/${total} transactions have been successfully completed.`,
              description: () => (
                <a
                  href={csvDownload}
                  download="Bulk Transfer Error.csv"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download error reports
                </a>
              ),
            })
          );
        }
        setSubmitting(false);
        setImporting(false);
      })
      .catch((err: AxiosError<CustomError>) => {
        setSubmitting(false);
        if (err.response?.status !== 401) {
          dispatch(
            showToast({
              type: "error",
              title: err.response?.data.error.message ?? "Can not import data.",
            })
          );
        }
      });
  };

  if (!transactionId) {
    return (
      <Modal open={true}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Uploading...
          </Typography>
        </Box>
      </Modal>
    );
  }

  return (
    <TransferProvider.Provider value={contextData}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card>
          <CardContent sx={{ margin: ["10px", "10px", "15px"] }}>
            {step < 4 && (
              <Box>
                <ArrowBackIcon
                  onClick={() => {
                    setImporting(false);
                  }}
                />
              </Box>
            )}
            <Formik
              innerRef={userFormRef}
              initialValues={{ otp_code: "" }}
              onSubmit={submit}
              enableReinitialize
            >
              {({ handleSubmit, isSubmitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <Stack gap={2} mt={4}>
                    {renderUiByStep()}
                    {step !== 4 && (
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={
                          isSubmitting || (step === 3 && !values.otp_code)
                        }
                      >
                        {step === 3 ? "Confirm" : "Continue"}
                      </Button>
                    )}
                  </Stack>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </TransferProvider.Provider>
  );
};

export default BulkTransferForm;
