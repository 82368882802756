import colors from "constants/colors";

export const setListStyles = () => ({
  MuiListItemButton: {
    styleOverrides: {
      root: () => ({
        "&.Mui-selected: hover": {
          backgroundColor: "#5D31FF",
        },
        "&:hover": {
          backgroundColor: colors.Primary500,
          "& .MuiListItemIcon-root": {
            "& .MuiSvgIcon-root": {
              color: colors.White,
            },
          },
          "& .MuiTypography-root": {
            color: colors.White,
          },
        },
      }),
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: () => ({
        minWidth: "40px",
        "& .MuiSvgIcon-root": {
          color: colors.Primary500,
        },
      }),
    },
  },
});
