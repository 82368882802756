import { Box, Button, Typography } from "@mui/material";
import colors from "constants/colors";
import { useFormikContext } from "formik";
import { ITransactionProps } from "interfaces/transaction";
import { TransferProvider } from "pages/users/transfer";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { formatMoney, formatMoneyWithDecimal } from "utils/formatMoney";

const TransactionComplete = () => {
  const { data } = useContext(TransferProvider);
  const { values } = useFormikContext<ITransactionProps>();
  const navigate = useNavigate();

  return (
    <>
      <Box textAlign="center">
        <img
          src={`${process.env.PUBLIC_URL}/images/Icon.png`}
          alt="success"
          width="80px"
        />
      </Box>
      <Box>
        <Typography variant="h1" sx={{ textAlign: "center" }}>
          Transfer Complete
        </Typography>
      </Box>
      <Box>
        <Box>
          <Typography sx={{ textAlign: "center" }}>
            You have successfully transferred{" "}
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
        >
          <Typography sx={{ color: colors.Primary500 }} fontWeight={600}>
            {values?.currency_code}{" "}
            {formatMoneyWithDecimal(formatMoney(values?.amount))}
          </Typography>
          <Typography sx={{ marginLeft: "3px", marginRight: "3px" }}>
            to
          </Typography>{" "}
          <Typography
            sx={{ textAlign: "center", color: colors.Primary500 }}
            fontWeight={600}
          >{`${data.first_name} ${data.last_name}`}</Typography>
        </Box>
        <Box mt="35px">
          <Button
            variant="contained"
            onClick={() => navigate(`/accounts/${values?.currency_code}`)}
            fullWidth
          >
            Done
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TransactionComplete;
