/* eslint-disable no-lonely-if */
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useFormikContext } from "formik";
import { capitalizeWord } from "utils/capitalizeWord";

interface ISeaSelectProps {
  label?: string;
  name: string;
  fullWidth?: boolean;
  noBorder?: boolean;
  disabled?: boolean;
  options: string[];
}

const SeaSelect = ({ label, name, fullWidth, options }: ISeaSelectProps) => {
  const { values, setValues, touched, errors } = useFormikContext<any>();

  return (
    <Box>
      {label && (
        <InputLabel
          shrink
          error={touched[name] && Boolean(errors[name])}
          sx={{ whiteSpace: "break-spaces" }}
        >
          {label}
        </InputLabel>
      )}
      <Select
        value={values[name] ?? ""}
        onChange={(e) => {
          setValues({
            ...values,
            [name]: e.target.value,
          });
        }}
        fullWidth={fullWidth}
        error={touched[name] && Boolean(errors[name])}
      >
        {options.map((item) => (
          <MenuItem value={item} key={item}>
            {capitalizeWord(item)}
          </MenuItem>
        ))}
      </Select>
      {touched[name] && errors[name] && (
        <Box color="#d32f2f" fontWeight={400} fontSize={12} mt="3px">
          {errors[name]?.toString()}
        </Box>
      )}
    </Box>
  );
};

export default SeaSelect;
