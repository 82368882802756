import { Box, Divider, FormLabel, Typography } from "@mui/material";
import colors from "constants/colors";
import { useFormikContext } from "formik";
import { ITransactionProps } from "interfaces/transaction";
import { TransferProvider } from "pages/users/transfer";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { formatMoney, formatMoneyWithDecimal } from "utils/formatMoney";
import trackPathForAnalytics from "utils/trackPageForAnalytics";
import { t } from "utils/translate";
import TransactionOtp from "./TransactionOtp";

const TransactionComment = ({ transactionId }: any) => {
  const { data } = useContext(TransferProvider);
  const { values } = useFormikContext<ITransactionProps>();
  const { pathname } = useLocation();

  useEffect(() => {
    trackPathForAnalytics({
      path: pathname,
      search: "",
      title: "Transfer: Confirm Transfer",
    });
  }, []);

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Box sx={{ typography: "h1" }}>{t("confirm_transfer")}</Box>
        <FormLabel>
          {t("to")}{" "}
          <span
            style={{ color: "#5D31FF" }}
          >{`${data.first_name} ${data.last_name}`}</span>
          .
        </FormLabel>
      </Box>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormLabel>{t("you_send")}</FormLabel>
          <Typography color={colors.Black}>
            {formatMoneyWithDecimal(formatMoney(values?.amount))}{" "}
            {values?.currency_code}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormLabel>{t("they_receives")}</FormLabel>
          <Typography color={colors.Black}>
            {formatMoneyWithDecimal(formatMoney(values?.amount))}{" "}
            {values?.currency_code}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box mb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box mr={1}>
            <FormLabel>{t("comments_optional")}</FormLabel>
          </Box>
          <Typography color={colors.Black}>
            {values.comments == "" ? "-" : values.comments}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box>
        <TransactionOtp transactionId={transactionId} />
      </Box>
    </>
  );
};

export default TransactionComment;
