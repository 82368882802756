import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { AxiosError } from "axios";
import GradientIcon from "components/shared/GradientIcon";
import colors from "constants/colors";
import { CustomError } from "interfaces/error";
import { IColumnProps } from "interfaces/mui-column";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toast/action";
import { IFilterProps, getTransactionByCurrency } from "utils/apiProvider";
import { renderMoney } from "utils/formatMoney";
import authProvider from "utils/authProvider";
import { UTCLocalTimeStampCompact } from "components/shared/UTCLocalTimestampComponent";

const PastTransactionTable = ({
  id,
  isImporting,
  currency,
  rows,
  setRows,
}: {
  id: string;
  isImporting: boolean;
  currency: string;
  rows: any;
  setRows: any;
}) => {
  const columns: IColumnProps[] = [
    {
      field: "created_at",
      headerName: "Date of transfer",
      sortable: true,
      formatter: (p: any) => (
        <Link href={`/transaction/${currency}/${p.id}`}>
          <UTCLocalTimeStampCompact date={p.created_at} />
        </Link>
      ),
    },
    {
      field: "recipient_first_name",
      headerName: "Name",
      sortable: false,
      formatter: (p: any) =>
        `${p.recipient_first_name} ${p.recipient_last_name}`,
    },
    {
      field: "amount",
      headerName: "Transfer amount",
      sortable: true,
      align: "right",
      formatter: (p: any) => renderMoney(p.amount),
    },
    {
      field: "dump_field",
      headerName: "Status",
      sortable: true,
      formatter: (_p: any) => (
        <Box mb="0.25rem">
          <span
            style={{
              textAlign: "center",
              display: "inline-block",
              fontSize: 13,
              padding: "4px 8px",
              borderRadius: 100,
              color: colors.Success500,
              background: colors.Success300,
            }}
          >
            Completed
          </span>
        </Box>
      ),
    },
  ];
  const [filter, setFilter] = useState<IFilterProps>({
    cursor: null,
    limit: 25,
    sort: [{ created_at: "desc" }],
  });

  const [page, setPage] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [nextCursor, setNextCursor] = useState<number | null>(null);
  const [totalPastTransaction, setTotalPastTransaction] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isImporting) {
      setFilter({
        ...filter,
        cursor: null,
      });
    }
  }, [isImporting]);

  const fetchTransactionByCurrency = async () => {
    const userId = await authProvider.getUserID();
    getTransactionByCurrency(id, filter, userId)
      .then((resp: any) => {
        setRows(resp.data.transactions);
        setLoaded(true);
        setNextCursor(resp.data.next_cursor);
        setTotalPastTransaction(resp.data.total_count);
      })
      .catch((err: AxiosError<CustomError>) => {
        setRows([]);
        if (err.response?.status !== 401) {
          dispatch(
            showToast({
              type: "error",
              title: err.response?.data.error.message ?? "Can not get data.",
            })
          );
        }
      });
  };

  useEffect(() => {
    fetchTransactionByCurrency();
  }, [filter]);

  const handleSort = (column: IColumnProps) => {
    const findExisted = filter.sort?.find(
      (s: any) => Object.keys(s)?.[0] === column.field
    );
    const order =
      findExisted?.[Object.keys(findExisted)?.[0]] === "desc" ? "asc" : "desc";
    setFilter({
      ...filter,
      sort: [
        {
          [column.field]: order,
        },
      ],
    });
  };

  const renderTransaction = () => {
    if (!rows) {
      return <Box>The currency is not supported for view</Box>;
    }
    if (!loaded) {
      return <Box>Loading...</Box>;
    }
    if (rows.length === 0) {
      return (
        <Box textAlign="center" pb={5}>
          <Box
            bgcolor="#EEEEFF"
            margin="50px auto 20px"
            width={80}
            height={80}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="50%"
          >
            <GradientIcon
              icon="InfoOutlinedIcon"
              sx={{ height: 50, width: 50 }}
            />
          </Box>
          <Typography
            fontSize="24px"
            fontWeight={600}
            color="#1D213A"
            sx={{ mb: 1 }}
          >
            No past transactions
          </Typography>
          <Typography>Your past transactions will be shown here.</Typography>
        </Box>
      );
    }
    return (
      <>
        <TableContainer sx={{ marginTop: 0 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column: IColumnProps) => (
                  <TableCell
                    key={column.field}
                    width={column.width ? `${column.width}%` : "auto"}
                    align={column.align ?? "left"}
                  >
                    {column.sortable ? (
                      <TableSortLabel
                        active={
                          !!filter.sort?.find(
                            (s: any) => Object.keys(s)?.[0] === column.field
                          )
                        }
                        onClick={() => handleSort(column)}
                        direction={
                          filter.sort?.find(
                            (s: any) => Object.keys(s)?.[0] === column.field
                          )?.[column.field]
                            ? filter.sort?.find(
                                (s: any) => Object.keys(s)?.[0] === column.field
                              )?.[column.field]
                            : "desc"
                        }
                      >
                        {column.headerName}
                      </TableSortLabel>
                    ) : (
                      <>{column.headerName}</>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.field];
                      return (
                        <TableCell
                          align={column.align ?? "left"}
                          key={column.field}
                          sx={{ verticalAlign: "middle" }}
                        >
                          {column.formatter ? column.formatter(row) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalPastTransaction}
          rowsPerPage={filter.limit}
          page={page - 1}
          onPageChange={(event, newPage) => {
            setPage(newPage + 1);
            setFilter({
              ...filter,
              cursor: nextCursor,
            });
          }}
          onRowsPerPageChange={(e: ChangeEvent<any>) => {
            setFilter({
              ...filter,
              cursor: null,
              limit: e.target.value,
            });
          }}
        />
      </>
    );
  };

  return renderTransaction();
};

export default PastTransactionTable;
