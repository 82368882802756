/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable no-param-reassign */
import produce, { Draft } from "immer";
import { IReduxToastState } from "interfaces/redux";
import { TOAST_CLEAR, TOAST_SHOW } from "./action";

export const initialState = {
  type: null,
  title: "",
  description: null,
};

const toastReducer = (
  state: IReduxToastState = initialState,
  action: {
    payload: IReduxToastState;
    type: string;
  }
) =>
  produce(state, (draft: Draft<IReduxToastState>) => {
    switch (action?.type) {
      case TOAST_SHOW:
        draft.type = action.payload.type;
        draft.title = action.payload.title;
        draft.description = action.payload.description;
        break;
      case TOAST_CLEAR:
        draft.type = null;
        draft.title = "";
        draft.description = "";
        break;
      default:
    }
  });

export default toastReducer;
