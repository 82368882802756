import { Box, Button, FormLabel } from "@mui/material";
import { AxiosError } from "axios";
import CountDown from "components/form/Countdown";
import colors from "constants/colors";
import { useFormikContext } from "formik";
import { CustomError } from "interfaces/error";
import { ITransactionProps } from "interfaces/transaction";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { showToast } from "redux/toast/action";
import { resendOtp } from "utils/apiProvider";
import trackPathForAnalytics from "utils/trackPageForAnalytics";

const TransactionOtpBulk = ({ transactionId, isBulk }: any) => {
  const { values, setValues } = useFormikContext<ITransactionProps>();

  const [disabledResend, setDisableResend] = useState(false);
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  useEffect(() => {
    trackPathForAnalytics({
      path: pathname,
      search: "",
      title: "OTP Entry",
    });
  }, []);

  const handleResendOtp = async () => {
    resendOtp(transactionId, isBulk)
      .then(() => {
        dispatch(
          showToast({
            type: "success",
            title: "OTP has been resent successfully to your email.",
          })
        );
        setDisableResend(true);
      })
      .catch((err: AxiosError<CustomError>) => {
        if (err.response?.status !== 401) {
          dispatch(
            showToast({
              type: "error",
              title: err.response?.data.error.message ?? "Can not resent otp.",
            })
          );
        }
      });
  };

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ typography: "h1" }}>2-Step Verification</Box>
        <FormLabel sx={{ mb: 3 }}>
          A 6-digit code has been sent to your email address.
        </FormLabel>
      </Box>
      <OtpInput
        value={values.otp_code}
        onChange={(c: string) => {
          setValues({
            ...values,
            otp_code: c,
          });
        }}
        numInputs={6}
        inputStyle={{
          height: "50px",
          width: window.innerWidth < 500 ? "40px" : "50px",
          borderRadius: "8px",
          border: `1px solid ${colors.Grey300}`,
          ":focusVisible": {
            outline: "red auto 1px",
          },
        }}
        focusStyle={{
          outline: "none",
          border: "2px solid #5D31FF",
          borderRadius: "8px",
        }}
        containerStyle={{
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      />
      <Button
        variant="text"
        sx={{ fontSize: "16px", mb: 2, textDecoration: "underline" }}
        onClick={handleResendOtp}
        disabled={disabledResend}
      >
        Resend OTP{" "}
        {disabledResend && (
          <CountDown seconds={60} onFinish={() => setDisableResend(false)} />
        )}
      </Button>
    </>
  );
};

export default TransactionOtpBulk;
