import { createTheme, Theme } from "@mui/material";
import colors from "constants/colors";
import { setAppBar } from "./app-bar";
import { setButtonStyles } from "./button";
import { setFormStyles } from "./form";
import { setListStyles } from "./list";
import { setTableStyles } from "./table";

export const theme: Theme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: colors.Grey100,
    },
    primary: {
      main: colors.Primary500,
      contrastText: colors.Black,
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: 24,
      lineHeight: "150%",
      fontWeight: 600,
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,
    },
    h3: {
      fontSize: 18,
      fontWeight: 600,
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: () => ({
          "&.Mui-focusVisible": {
            border: "none",
            outline: 0,
            color: "#381C9F",
          },
        }),
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: () => ({
          "& .MuiAlert-standardError .MuiAlert-message": {
            color: colors.Error500,
          },
          "& .MuiAlert-standardError .MuiSvgIcon-root": {
            color: colors.Error500,
          },
        }),
      },
    },
    ...setFormStyles(),
    ...setAppBar(),
    ...setListStyles(),
    ...setTableStyles(),
    ...setButtonStyles(),
  },
});
