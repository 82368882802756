import { IReduxBaseAction } from "interfaces/redux";
import { call, put, takeLatest } from "redux-saga/effects";
import authProvider from "utils/authProvider";
import {
  USER_CHECK,
  USER_LOGIN,
  USER_LOGOUT,
  USER_REFRESH_TOKEN,
} from "./types";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* userLoginSaga(action: IReduxBaseAction) {
  const { payload, meta } = action;
  const { success, failure } = meta;
  try {
    const resp: ResponseGenerator = yield call(authProvider.login, payload);
    yield put({ meta, type: success as string, payload: resp });
  } catch (error: any) {
    yield put({ meta, type: failure as string, error: true });
  }
}

function* userCheckSaga(action: IReduxBaseAction) {
  const { meta } = action;
  const { success, failure } = meta;
  try {
    const resp: ResponseGenerator = yield call(authProvider.checkAuth);
    if (!resp) {
      yield put({ meta, type: failure as string, error: true });
    } else {
      yield put({ meta, type: success as string, payload: resp });
    }
  } catch (error: any) {
    yield put({ meta, type: failure as string, error: error?.message });
  }
}

function* userRefreshTokenSaga(action: IReduxBaseAction) {
  const { payload, meta } = action;
  const { success, failure } = meta;
  try {
    const resp: ResponseGenerator = yield call(
      authProvider.refreshToken,
      payload
    );
    yield put({ meta, type: success as string, payload: resp });
  } catch (error: any) {
    yield put({ meta, type: failure as string, error: error?.message });
  }
}

function* userLogoutSaga(action: IReduxBaseAction) {
  const { meta } = action;
  const { success, failure } = meta;
  try {
    const resp: ResponseGenerator = yield call(authProvider.logout);
    yield put({ meta, type: success as string, payload: resp });
  } catch (error: any) {
    yield put({ meta, type: failure as string, error: error?.message });
  }
}

function* authSaga() {
  yield takeLatest(USER_LOGIN, userLoginSaga);
  yield takeLatest(USER_CHECK, userCheckSaga);
  yield takeLatest(USER_REFRESH_TOKEN, userRefreshTokenSaga);
  yield takeLatest(USER_LOGOUT, userLogoutSaga);
}

export default authSaga;
