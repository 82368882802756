import { Box, Card, Stack, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { t } from "utils/translate";
import { BulkTransferProvider } from "./BulkTransfer";
import { getBulkDisbursalLatestStatus } from "utils/apiProvider";
import LinearProgress from "@mui/material/LinearProgress";
import * as helpers from "./helpers";

const BulkTransferImportInProgress = () => {
  const contextData = useContext(BulkTransferProvider);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const fileKey = localStorage.getItem("fileKey");
      if (!fileKey) return;
      const result = await getBulkDisbursalLatestStatus(fileKey);
      updateJobData(result.data);
      const status = result.data.job_data.status;

      if (
        [
          helpers.JobStatus.VALIDATION_COMPLETE_SUCESS,
          helpers.JobStatus.VALIDATION_COMPLETE_FAILED,
          helpers.JobStatus.DISBUSTAL_COMPLETE,
        ].includes(status)
      ) {
        contextData.setStep(-1);
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const updateJobData = (info: any) => {
    contextData.setJobInfo({
      jobId: info.job_data.pk,
      status: info.status,
      info: helpers.safeJsonParse(info.job_data.info, []),
      csvResult: info.csv_file_url,
      otpCode: "",
      transactionInfo: {
        totalAmount: 0,
        totalTransactions: 0,
      },
    });
  };

  const isBulkTransfer = [
    helpers.JobStatus.DISBUSTAL_COMPLETE,
    helpers.JobStatus.DISBUSTAL_RUNNING,
    helpers.JobStatus.DISBUSTAL_INITITATED,
  ].includes(contextData.jobInfo.status);

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Card
        sx={{
          width: 411,
          px: "28px",
          py: "32px",
          mt: 5,
        }}
      >
        <Stack direction="column" alignItems="start" useFlexGap>
          <Typography sx={{ fontWeight: 700, fontSize: "28px" }}>
            {!isBulkTransfer
              ? t("csv_validation_in_progress")
              : t("bulk_payout_in_progress")}
          </Typography>
          <Typography sx={{ fontSize: "16px" }}>
            {t("csv_upload_in_progress_info")}
          </Typography>
        </Stack>
        <LinearProgress
          variant="determinate"
          value={+contextData.jobInfo.info[0] || 0}
        />
      </Card>
    </Box>
  );
};

export default BulkTransferImportInProgress;
