import {
  ContactPageOutlined,
  CreditCard,
  ErrorOutlineOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Divider,
  Modal,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import StatusChip from "components/shared/StatusChip";
import colors from "constants/colors";
import dayjs from "dayjs";
import {
  IWalletHolderProps,
  USER_STATUS,
  VERIFICATION_STATUS,
  wallerHolderInit,
} from "interfaces/wallet-holder";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "redux/toast/action";
import { style } from "themes/modal";
import {
  archiveUser,
  getAllCardsForUser,
  getWalletHolderDetail,
} from "utils/apiProvider";
import {
  activationStatusColor,
  cardStatusColor,
  riskStatusColor,
  toStatusChipProps,
  verificationStatusColor,
} from "utils/statusColors";
import {
  AssignCardActionButton,
  CallActionButton,
  EmailActionButton,
  MarkCardAsSentButton,
  PrintCardButton,
  SendAccountNumberActionButton,
  SendInviteActionButton,
  TransferActionButton,
  WhatsAppActionButton,
} from "../ActionButtons";
import { CardStatusList, cardsStatusText } from "utils/cardHelpers";

export const DangerIcon = () => (
  <Box
    sx={{
      height: "70px",
      width: "70px",
      background: colors.Error300,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "100%",
      margin: "10px auto",
    }}
  >
    <InfoOutlinedIcon sx={{ fill: "#CC2222", height: "40px", width: "40px" }} />
  </Box>
);

const Chip = ({
  icon,
  text,
  colors: statusChipColors,
  loading,
}: {
  icon: JSX.Element;
  text: string;
  colors: [background: string, text: string];
  loading: boolean;
}) => (
  <Box sx={{ textTransform: "capitalize" }}>
    {loading ? (
      <Skeleton
        variant="rounded"
        sx={{
          mb: "6px",
          height: "30px",
          width: "108px",
          padding: "4px 8px",
          borderRadius: 100,
        }}
      />
    ) : (
      <StatusChip
        icon={icon}
        status={{
          name: text.toLowerCase().replace("_", " "),
          ...toStatusChipProps(statusChipColors),
        }}
      />
    )}
  </Box>
);

const WhDetailHeader = ({
  id,
  data,
}: {
  id: string;
  data?: IWalletHolderProps;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [wh, setWh] = useState<IWalletHolderProps>(wallerHolderInit);

  const fetchDetail = async () => {
    const [wh, card_statuses] = await Promise.all([
      getWalletHolderDetail(id),
      getAllCardsForUser("" + id, "physical"),
    ]);
    setWh({ ...wh, card_statuses });
  };

  const fetchCards = useCallback(() => {
    setWh((wh) => ({ ...wh, card_statuses: undefined }));
    getAllCardsForUser("" + id, "physical").then((card_statuses) => {
      setWh((wh) => ({ ...wh, card_statuses }));
    });
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchDetail();
    }
  }, [id, data]);

  const handleArchiveUser = () => {
    setSubmitting(true);
    archiveUser(wh.id, true)
      .then(() => {
        setShowArchiveModal(false);
        setWh({
          ...wh,
          activation_status: USER_STATUS.archived.code,
        });
        dispatch(
          showToast({
            type: "success",
            title: `User has been archived successfully!`,
          })
        );
        setSubmitting(false);
      })
      .catch(
        (
          err: AxiosError<{
            error: {
              message: string;
            };
          }>
        ) => {
          setSubmitting(false);
          if (err.response?.status !== 401) {
            dispatch(
              showToast({
                type: "error",
                title:
                  err.response?.data.error.message ?? "Can not archive user",
              })
            );
          }
        }
      );
  };
  const onUpdatedProps = () => {};
  const handleDeleteUser = () => {
    setSubmitting(true);
    archiveUser(wh.id, true)
      .then(() => {
        setShowArchiveModal(false);
        dispatch(
          showToast({
            type: "success",
            title: `User has been deleted successfully!`,
          })
        );
        navigate("/");
      })
      .catch(
        (
          err: AxiosError<{
            error: {
              message: string;
            };
          }>
        ) => {
          setSubmitting(false);
          if (err.response?.status !== 401) {
            dispatch(
              showToast({
                type: "error",
                title:
                  err.response?.data?.error?.message ??
                  "Can not delete wallet holder",
              })
            );
          }
        }
      );
  };

  return (
    <>
      <Modal
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
      >
        <Box sx={style}>
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            sx={{ cursor: "pointer" }}
          />
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <DangerIcon />
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 2 }}
            >
              Delete User
            </Typography>
            <Typography id="modal-modal-description">
              Are you sure you want to delete
            </Typography>
            <Typography sx={{ color: colors.Primary500 }}>
              {` ${wh.first_name} ${wh.last_name} `}?
            </Typography>
            <Box display="flex" justifyContent="space-between" mt={3}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleDeleteUser}
                disabled={submitting}
              >
                Confirm
              </Button>
              <Button
                type="button"
                fullWidth
                sx={{
                  border: "1px solid #BEADFF",
                  color: "#381C9F",
                  borderRadius: "100px",
                  fontSize: "14px",
                  padding: "10px 20px",
                  textAlign: "center",
                  display: "inline-block",
                  ml: 2,
                  cursor: "pointer",
                  "&:hover": {
                    background: "rgba(93, 49, 255, 0.04)",
                  },
                }}
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={showArchiveModal}
        onClose={() => {
          setShowArchiveModal(false);
        }}
      >
        <Box sx={style}>
          <CloseIcon
            onClick={() => setShowArchiveModal(false)}
            sx={{ cursor: "pointer" }}
          />
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <DangerIcon />
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 2 }}
            >
              Archive User
            </Typography>
            <Typography id="modal-modal-description" sx={{ display: "inline" }}>
              Are you sure you want to archive
            </Typography>
            <Typography
              sx={{ display: "inline", color: colors.Primary500 }}
            >{` ${wh.first_name} ${wh.last_name} `}</Typography>
            ?
            <Box display="flex" justifyContent="space-between" mt={3}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleArchiveUser}
                disabled={submitting}
              >
                Confirm
              </Button>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  border: "1px solid #BEADFF",
                  color: "#381C9F",
                  borderRadius: "100px",
                  fontSize: "14px",
                  padding: "10px 20px",
                  textAlign: "center",
                  display: "inline-block",
                  ml: 2,
                  cursor: "pointer",
                  "&:hover": {
                    background: "rgba(93, 49, 255, 0.04)",
                  },
                }}
                onClick={() => setShowArchiveModal(false)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box>
        <Link to="/" style={{ fontSize: "14px" }}>
          Wallet Holders
        </Link>
        <ArrowForwardIosIcon sx={{ fontSize: 10, marginLeft: 1 }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0 20px",
          flexDirection: ["column", "column", "column", "row"],
        }}
      >
        <Box>
          <Box sx={{ typography: "h1" }}>
            {wh.first_name ? (
              `${wh.first_name} ${wh.last_name}`
            ) : (
              <Skeleton sx={{ width: "200px" }} />
            )}
          </Box>
          <Box style={{ fontWeight: "normal", fontSize: 20, lineHeight: 1.2 }}>
            {wh.wallet_number ? (
              `${wh.wallet_number}`
            ) : (
              <Skeleton sx={{ width: "180px" }} />
            )}
          </Box>
          {wh.last_transaction && (
            <Box sx={{ color: colors.Grey700, fontSize: 16 }}>
              Last transaction:{" "}
              {dayjs(wh.last_transaction).format("DD MMM YYYY, hh:mm A")}
            </Box>
          )}
          <Box sx={{ mt: 1 }}>
            <Stack direction="row" spacing={1}>
              <Chip
                loading={!wh.first_name}
                icon={<PersonOutlineOutlined />}
                colors={activationStatusColor(wh.activation_status)}
                text={wh.activation_status}
              />
              <Chip
                loading={!wh.activation_status}
                icon={<ContactPageOutlined />}
                colors={verificationStatusColor(wh.verification_status)}
                text={wh.verification_status}
              />
              <Chip
                loading={!wh.activation_status}
                icon={<ErrorOutlineOutlined />}
                colors={riskStatusColor(wh.risk_status)}
                text={wh.risk_status}
              />
              <Tooltip
                placement="bottom"
                title={<CardStatusList cardStatuses={wh.card_statuses} />}
              >
                <span>
                  <Chip
                    loading={wh.card_statuses === undefined}
                    icon={<CreditCard />}
                    colors={cardStatusColor(wh.card_statuses)}
                    text={cardsStatusText(wh.card_statuses)}
                  />
                </span>
              </Tooltip>
            </Stack>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Stack
              sx={{ pl: 0.5 }}
              direction="row"
              spacing={2}
              flexWrap="wrap"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <CallActionButton p={wh} />
              <WhatsAppActionButton p={wh} />
              <EmailActionButton p={wh} />
              <SendInviteActionButton p={wh} onUpdatedProps={onUpdatedProps} />
              <SendAccountNumberActionButton p={wh} />
              <TransferActionButton p={wh} />
              {/* <RequestNewPhysicalCard onUpdatedProps={fetchCards} p={wh} /> */}
              <AssignCardActionButton onUpdatedProps={fetchCards} p={wh} />
              <PrintCardButton p={wh} />
              <MarkCardAsSentButton onUpdatedProps={fetchCards} p={wh} />
            </Stack>
          </Box>
        </Box>

        <Box sx={{ mt: 1 }}>
          {wh?.activation_status.toLowerCase() !== USER_STATUS.archived.code &&
            wh?.verification_status &&
            [
              VERIFICATION_STATUS.NOT_SUBMITTED,
              VERIFICATION_STATUS.PREPARING,
              VERIFICATION_STATUS.REJECTED,
            ].includes(wh?.verification_status?.toLowerCase()) && (
              <Button
                variant="outlined"
                sx={{ p: "8px 25px" }}
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </Button>
            )}
          {wh?.activation_status !== USER_STATUS.archived.code &&
            wh?.verification_status &&
            wh?.verification_status?.toLowerCase() ===
              VERIFICATION_STATUS.APPROVED && (
              <Button
                variant="outlined"
                sx={{ p: "8px 25px" }}
                onClick={() => setShowArchiveModal(true)}
              >
                Archive
              </Button>
            )}
        </Box>
      </Box>
    </>
  );
};

export default WhDetailHeader;
