/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-lonely-if */
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Input, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { formatMoney } from 'utils/formatMoney';
import { isNumber } from 'utils/regex';

interface ISeaInputProps {
  label?: string;
  type?: string;
  placeholder?: string;
  name: string;
  fullWidth?: boolean;
  noBorder?: boolean;
  disabled?: boolean;
  mode?: 'currency';
  maxLength?: number;
  helpText?: string;
  subText?: string;
  startAdornment?: Parameters<typeof Input>[0]['startAdornment'];
}

const SeaInput = ({ label, placeholder, name, fullWidth, type, noBorder, disabled, mode, maxLength, helpText, subText, startAdornment }: ISeaInputProps) => {
  const { values, handleChange, touched, errors, setValues, handleBlur } = useFormikContext<any>();

  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState('text');

  useEffect(() => {
    if (type) {
      setInputType(type);
    }
  }, [type]);

  useEffect(() => {
    if (type === 'password') {
      if (showPassword) {
        setInputType('text');
      } else {
        setInputType('password');
      }
    }
  }, [showPassword]);

  const getInputProps = () => {
    const inputProps: Parameters<typeof Input>[0] = {};

    if (type === 'password') {
      inputProps.endAdornment = (
        <InputAdornment position="end">
          <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
          </IconButton>
        </InputAdornment>
      );
    }

    if (startAdornment) inputProps.startAdornment = startAdornment;

    return inputProps;
  };

  const onChange = (e: React.ChangeEvent<any>) => {
    if (!mode) {
      handleChange(e);
    } else {
      const money = e.target.value.split(',').join('').split('.').join('');
      if (money === '' || isNumber(money)) {
        setValues({
          ...values,
          [name]: formatMoney(e.target.value)
        });
      }
    }
  };

  const onBlur = (e: React.ChangeEvent<any>) => {
    handleBlur(e);

    if (mode === 'currency' && e.target.value.length > 0) {
      const money = e.target.value.split('.');

      setValues({
        ...values,
        [name]: money.length === 1 ? `${e.target.value}.00` : e.target.value
      });
    }
  };

  return (
    <Box>
      {label && (
        <InputLabel shrink error={touched[name] && Boolean(errors[name])} sx={{ whiteSpace: 'break-spaces' }}>
          {label}
        </InputLabel>
      )}
      {subText && (
        <Typography fontSize={12} mb={1} mt={-1} color="#555566">
          {subText}
        </Typography>
      )}
      <TextField
        id={name}
        fullWidth={fullWidth}
        InputProps={getInputProps()}
        placeholder={placeholder ?? ''}
        value={values[name]}
        onChange={onChange}
        onBlur={onBlur}
        error={touched[name] && Boolean(errors[name])}
        // helperText={helpText}
        helperText={touched[name] && errors[name] ? errors[name]?.toString() : ''}
        type={inputType}
        className={noBorder ? 'MuiInputNoBorder' : ''}
        disabled={disabled}
        inputProps={{ maxLength }}
      />
      {helpText && (
        <Typography fontSize={12} mt={1}>
          {helpText}
        </Typography>
      )}
    </Box>
  );
};

export default SeaInput;
