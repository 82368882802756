import { Box, Button, Modal, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { style } from "themes/modal";
import { exportWalletHolders } from "utils/apiProvider";
import { t } from "utils/translate";

const ExportWalletHolder = () => {
  const linkRef = useRef<any>();
  const [exporting, setExporting] = useState(false);
  const handleExport = async () => {
    setExporting(true);
    exportWalletHolders().then((resp) => {
      linkRef.current.href = resp.data.csv_download_url;
      linkRef.current.setAttribute(
        "download",
        `wallet-holders-${dayjs().format("DDMMYYYYHHmm")}.csv`
      );
      linkRef.current.click();
      setExporting(false);
    });
  };

  return (
    <>
      {exporting && (
        <Modal open={exporting}>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Exporting...
            </Typography>
          </Box>
        </Modal>
      )}
      <Button
        variant="outlined"
        onClick={handleExport}
        sx={{
          border: "none",
          color: "#5D31FF",
          textDecoration: "underline",
          fontSize: "14px",
          padding: 0,
          textAlign: "center",
          display: "inline-block",
          float: "right",
          marginTop: [1, 1, 0, 0],
          marginBottom: 3,
          "&:hover": {
            border: "none",
            background: "none",
            color: "#381C9F",
            textDecoration: "underline",
          },
          "&.Mui-focusVisible": {
            background: "none",
            color: "#381C9F",
          },
        }}
      >
        {t("export_users_button_label")}
      </Button>
      <a href="/" ref={linkRef} style={{ display: "none" }}>
        a
      </a>
    </>
  );
};

export default ExportWalletHolder;
