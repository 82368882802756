import {
  Box,
  Button,
  Card,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import colors from "constants/colors";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface PrintCardProp {
  whName: string;
  handleClose: () => void;
  last4Digits: string;
}

const PrintSeaboardCard = ({
  whName,
  handleClose,
  last4Digits,
}: PrintCardProp) => {
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "Seabaord Card " + whName,
    onBeforePrint: () => {
      setTextColor(colors.Black);
    },
    onAfterPrint: () => {
      setTextColor(colors.White);
    },
    removeAfterPrint: true,
  });

  const [textColor, setTextColor] = React.useState(colors.White);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     handlePrint(null, () => contentToPrint.current);
  //   }, 500);

  //   return () => clearTimeout(timeout);
  // }, []);

  return (
    <Stack direction={"column"}>
      <Stack direction={"row"} sx={{ pl: 2 }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          size="small"
          color="inherit"
        >
          <CloseIcon />
        </IconButton>
      </Stack>

      <Box sx={{ pr: 3, pl: 3, pt: 1 }}>
        <Typography fontSize="28px" fontWeight={700} color={colors.BlueDark}>
          {"Print Card"}
        </Typography>
        <Typography
          style={{ display: "inline-block", paddingTop: 1 }}
          component="span"
          fontSize="16px"
          fontWeight={500}
          color={colors.Neutral800}
        >
          {last4Digits}{" "}
          <Typography
            style={{ display: "inline-block" }}
            component="span"
            fontSize="14px"
            fontWeight={400}
          >
            {"for"}
          </Typography>{" "}
          {whName}
        </Typography>

        <Card
          variant="elevation"
          sx={{
            width: "337.5px",
            height: "212.5px",
            backgroundImage: "url('/files/bg_2_16_9.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            borderRadius: "12px",
            mt: 2,
          }}
        >
          <div ref={contentToPrint}>
            <Stack
              sx={{ height: "212.5px", width: "337.5px", pb: 3, pl: 2 }}
              direction="column-reverse"
            >
              <Typography fontSize="18px" fontWeight={500} color={textColor}>
                {whName?.toUpperCase().replace(/<\/[^>]+(>|$)/g, "")}
              </Typography>
            </Stack>
          </div>
        </Card>

        <Stack
          direction={"row-reverse"}
          spacing={1}
          sx={{ flexWrap: "wrap", height: "48px", mt: 2 }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setTextColor(colors.Black);
              setTimeout(() => {
                handlePrint(null, () => contentToPrint.current);
              }, 100);
            }}
            sx={{ fontSize: 14, pl: 2, pr: 2, height: 40 }}
          >
            {"Print"}
          </Button>
          <Button
            color="inherit"
            size="small"
            variant="outlined"
            onClick={handleClose}
            sx={{ fontSize: 14, pl: 2, pr: 2, height: 40 }}
          >
            {"Cancel"}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

export default PrintSeaboardCard;
