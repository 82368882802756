import { IWalletHolderProps } from "interfaces/wallet-holder";
import { SyntheticEvent, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  styled,
  SxProps,
  Tooltip,
  Zoom,
} from "@mui/material";
import {
  Phone,
  WhatsApp,
  MailOutline,
  ForwardToInbox,
  LocalAtm,
  AddCard,
  TaskAlt,
  ContentCopy,
  CardGiftcard,
  Print,
} from "@mui/icons-material";
import {
  sendAccountsEmail as sendAccountsEmailAPIAction,
  sendWelcomeEmail,
  sendCard,
  requestNewPhysicalCard,
} from "utils/apiProvider";
import { showToast } from "redux/toast/action";
import { AxiosError } from "axios";
import { CustomError } from "interfaces/error";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import AssignCardModal from "components/users/AssignCard";
import colors from "constants/colors";
import { formatPhoneNumber } from "utils/formatPhoneNumber";
import React from "react";
import PrintSeaboardCard from "pages/wallet-holder/PrintSeaboardCard";

export type WalletHolderActionProps = {
  p: IWalletHolderProps;
  sx?: SxProps;
};

export type UpdatedProps = { field: string; value: string }[];

export type OnUpdatedProps = {
  onUpdatedProps: (id: string, props: UpdatedProps) => void;
};

export type ActionButtonProps = {
  sx?: SxProps;
  text: string | JSX.Element;
  icon: JSX.Element;
  href?: string;
  disabled?: boolean;
  loading?: boolean;
  tooltip?: string;
  onClick?: (e: SyntheticEvent) => void;
};

type CopyButtonProps = {
  tooltip: string;
  copy: string;
};

const MAX_PHYSICAL_CARDS = parseInt(
  process.env.REACT_APP_MAX_PHYSICAL_CARDS ?? "",
  10
);

function WithCopyButton(props: CopyButtonProps & { children: JSX.Element }) {
  return (
    <Box sx={{ display: "flex" }}>
      {props.children}
      <CopyButton {...props} />
    </Box>
  );
}

function CopyButton(props: CopyButtonProps) {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(
      showToast({
        type: "success",
        title: `Copied "${props.copy}" to clipboard`,
      })
    );
    navigator.clipboard.writeText(props.copy);
  };
  return (
    <Tooltip title={props.tooltip}>
      <ContentCopy
        onClick={onClick}
        fontSize="inherit"
        sx={{
          cursor: "pointer",
          mt: "5px",
          ml: 1,
          color: colors.GreyscaleDefault,
          "&:hover": {
            color: colors.GreyscaleBlack,
          },
        }}
      />
    </Tooltip>
  );
}

export function TableActionButton({
  sx,
  tooltip,
  text,
  icon,
  href,
  disabled,
  loading,
  onClick,
}: ActionButtonProps) {
  const Wrapper =
    typeof tooltip === "string"
      ? ({ children }: { children: JSX.Element }) => (
          <Tooltip
            sx={{ fontSize: "30px" }}
            TransitionComponent={Zoom}
            title={tooltip}
          >
            {children}
          </Tooltip>
        )
      : ({ children }: { children: JSX.Element }) => children;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Wrapper>
        <span>
          <Button
            disabled={loading || disabled}
            {...(href ? { href, target: "_blank" } : {})}
            startIcon={
              loading ? <CircularProgress color={"inherit"} size={20} /> : icon
            }
            onClick={onClick}
            sx={{
              alignItems: "left",
              justifyContent: "left",
              width: "100%",
              ...(sx ?? {}),
            }}
          >
            {text}
          </Button>
        </span>
      </Wrapper>
    </Box>
  );
}

export function CallActionButton({ p }: WalletHolderActionProps) {
  return (
    <WithCopyButton
      tooltip="Copy phone number to clipboard"
      copy={formatPhoneNumber(p.phone_code, p.phone)}
    >
      <TableActionButton
        tooltip={"Call " + formatPhoneNumber(p.phone_code, p.phone, true)}
        sx={{ minWidth: 0 }}
        disabled={!p.phone}
        href={`tel:` + formatPhoneNumber(p.phone_code, p.phone)}
        icon={<Phone />}
        text={"Call"}
      />
    </WithCopyButton>
  );
}

export function WhatsAppActionButton({ p }: WalletHolderActionProps) {
  const phoneNumber = formatPhoneNumber(p.phone_code, p.phone);
  const whatsAppID = phoneNumber.replace("+", "");
  const link = `https://wa.me/` + whatsAppID;
  return (
    <WithCopyButton tooltip="Copy WhatsApp link to clipboard" copy={link}>
      <TableActionButton
        disabled={!p.phone}
        href={link}
        icon={<WhatsApp />}
        text="WhatsApp"
      />
    </WithCopyButton>
  );
}

export function EmailActionButton({ p }: WalletHolderActionProps) {
  return (
    <WithCopyButton
      tooltip="Copy email address to clipboard"
      copy={p.email.toLowerCase()}
    >
      <TableActionButton
        tooltip={p.email}
        disabled={!p.email}
        href={`mailto:` + p.email}
        icon={<MailOutline />}
        text="Email"
      />
    </WithCopyButton>
  );
}

export function SendAccountNumberActionButton({ p }: WalletHolderActionProps) {
  const dispatch = useDispatch();
  const isOK =
    p.activation_status === "ACTIVATED" &&
    p.risk_status === "CLEARED" &&
    p.verification_status === "APPROVED";
  const [isSendAccountsLoading, setIsSendAccountsLoading] = useState(false);
  const sendAccountsEmail = async (id: string) => {
    setIsSendAccountsLoading(true);
    sendAccountsEmailAPIAction(id)
      .then((resp: any) => {
        if (resp.status === 200) {
          dispatch(
            showToast({
              type: "success",
              title: "Account details sent to user",
            })
          );
        }
        setIsSendAccountsLoading(false);
      })
      .catch((err: AxiosError<CustomError>) => {
        if (err.response?.status !== 401) {
          dispatch(
            showToast({
              type: "error",
              title:
                err.response?.data.error.message ??
                "Could not send accounts details to user",
            })
          );
        }
        setIsSendAccountsLoading(false);
      });
  };
  return (
    <WithCopyButton
      tooltip="Copy account # to clipboard"
      copy={p.wallet_number}
    >
      <TableActionButton
        tooltip={"Send account # to seafarer via email"}
        loading={isSendAccountsLoading}
        onClick={(e) => {
          e.stopPropagation();
          sendAccountsEmail(p.id);
        }}
        disabled={!isOK}
        icon={<ForwardToInbox />}
        text="Send acct. #"
      />
    </WithCopyButton>
  );
}

export function TransferActionButton({ p }: WalletHolderActionProps) {
  const isOK =
    // p.activation_status === "ACTIVATED" &&
    p.risk_status === "CLEARED" && p.verification_status === "APPROVED";
  return (
    <TableActionButton
      tooltip={"Transfer money from pre-fund to seafarer"}
      href={`/wallet-holders/transfer/${p.id}`}
      disabled={!isOK}
      icon={<LocalAtm />}
      text="Transfer"
    />
  );
}

export function RequestNewPhysicalCard({
  p,
  onUpdatedProps,
}: WalletHolderActionProps & OnUpdatedProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const canRequest =
    p.card_statuses !== undefined &&
    p.card_statuses.filter((card) => card.status !== "ARCHIVED").length <
      MAX_PHYSICAL_CARDS;

  const onRequestCard = (id: string) => {
    setLoading(true);
    requestNewPhysicalCard(id)
      .then(() => {
        onUpdatedProps(p.id, []);
        dispatch(
          showToast({
            type: "success",
            title: "New card requested",
          })
        );
        setLoading(false);
      })
      .catch((err: AxiosError<CustomError>) => {
        if (err.response?.status !== 401)
          dispatch(
            showToast({
              type: "error",
              title:
                err.response?.data.error.message ??
                "Could not request new card",
            })
          );
        setLoading(false);
      });
  };

  return (
    <TableActionButton
      tooltip={"Request a new physical card for seafarer."}
      disabled={!canRequest}
      loading={loading}
      onClick={() => onRequestCard(p.id)}
      icon={<CardGiftcard />}
      text="Request"
    />
  );
}

export function AssignCardActionButton({
  p,
  onUpdatedProps,
}: WalletHolderActionProps & OnUpdatedProps) {
  const canAssign =
    p.risk_status === "CLEARED" &&
    p.verification_status === "APPROVED" &&
    p.card_statuses !== undefined &&
    p.card_statuses.filter(
      (card) => card.status !== "ARCHIVED" && card.status !== "REQUESTED"
    ).length < MAX_PHYSICAL_CARDS &&
    p.card_statuses.filter((card) => card.status === "ASSIGNED").length === 0;
  const text = (p.card_statuses ?? []).some(
    (card) => card.status === "REQUESTED"
  )
    ? "req"
    : "new";
  const [showAssignCardModal, setShowAssignCardModal] = useState(false);
  return (
    <>
      <TableActionButton
        tooltip={
          "Assign a physical card to seafarer (note that there can be only one assigned card per seafarer at any given time). `new` means that a new card is being created. `req` means that a requested card is being assigned to."
        }
        disabled={!canAssign}
        onClick={() => setShowAssignCardModal(true)}
        icon={<AddCard />}
        text={
          <>
            Assign&nbsp;(<samp>{text}</samp>)
          </>
        }
      />
      <AssignCardModal
        data={p}
        show={showAssignCardModal}
        updateRows={onUpdatedProps}
        hideDeco={false}
      />
    </>
  );
}
export function MarkCardAsSentButton({
  p,
  onUpdatedProps,
}: WalletHolderActionProps & OnUpdatedProps) {
  const dispatch = useDispatch();
  const canMarkSent =
    p.risk_status === "CLEARED" &&
    p.verification_status === "APPROVED" &&
    p.card_statuses !== undefined &&
    p.card_statuses.filter((card) => card.status === "ASSIGNED").length === 1;

  const last4 =
    (canMarkSent &&
      (p.card_statuses ?? [])
        .filter((card) => card.status === "ASSIGNED")[0]
        ?.masked_card_number.split(" ")
        .pop()) ||
    "0000";
  const [loading, setLoading] = useState(false);
  const onSendCard = (id: string) => {
    setLoading(true);
    sendCard(id)
      .then(() => {
        const now = dayjs().format();
        onUpdatedProps(p.id, [
          {
            field: "card_status",
            value: "SENT",
          },
          {
            field: "last_card_status_change_at",
            value: now,
          },
        ]);
        dispatch(
          showToast({
            type: "success",
            title: "Card has been sent successfully",
          })
        );
        setLoading(false);
      })
      .catch((err: AxiosError<CustomError>) => {
        if (err.response?.status !== 401) {
          dispatch(
            showToast({
              type: "error",
              title: err.response?.data.error.message ?? "Can not send card.",
            })
          );
        }
        setLoading(false);
      });
  };
  return (
    <TableActionButton
      tooltip={`Mark the card XXXX XXXX XXX ${last4} as sent`}
      disabled={!canMarkSent}
      onClick={() => onSendCard(p.id)}
      loading={loading}
      icon={<TaskAlt />}
      text={
        <>
          Mark&nbsp;<samp>{last4}</samp>&nbsp;as sent
        </>
      }
    />
  );
}

export function PrintCardButton({ p }: WalletHolderActionProps) {
  const canMarkSent =
    p.risk_status === "CLEARED" &&
    p.verification_status === "APPROVED" &&
    p.card_statuses !== undefined &&
    p.card_statuses.filter((card) => card.status === "ASSIGNED").length === 1;
  const last4 =
    (canMarkSent &&
      (p.card_statuses ?? [])
        .filter((card) => card.status === "ASSIGNED")[0]
        ?.masked_card_number.split(" ")
        .pop()) ||
    "0000";

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <TableActionButton
        tooltip={`Print the card XXXX XXXX XXX ${last4}`}
        disabled={!canMarkSent}
        // href={`/card/print/${p.id}/${p.first_name} ${p.last_name}/${assignedCardsId?.id}`}
        loading={false}
        icon={<Print />}
        text={
          <>
            Print&nbsp;<samp>{last4}</samp>&nbsp;
          </>
        }
        onClick={handleClickOpen}
      />
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogContent>
          <PrintSeaboardCard
            whName={`${p.first_name} ${p.last_name}`}
            handleClose={handleClose}
            last4Digits={last4}
          />
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}

export function SendInviteActionButton({
  p,
  onUpdatedProps,
}: WalletHolderActionProps & OnUpdatedProps) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isOK =
    (p.activation_status === "NOT_INVITED" ||
      p.activation_status === "INVITED") &&
    p.risk_status === "CLEARED" &&
    p.verification_status === "APPROVED";
  const sendEmail = async (id: string) => {
    setLoading(true);
    sendWelcomeEmail(id)
      .then((resp: any) => {
        if (resp.status === 200) {
          const now = dayjs().format();
          onUpdatedProps(p.id, [
            {
              field: "activation_status",
              value: "INVITED",
            },
            {
              field: "last_status_change_at",
              value: now,
            },
          ]);
          dispatch(
            showToast({
              type: "success",
              title: "Welcome email sent!",
            })
          );
        }
        setLoading(false);
      })
      .catch((err: AxiosError<CustomError>) => {
        if (err.response?.status !== 401) {
          dispatch(
            showToast({
              type: "error",
              title:
                err.response?.data.error.message ??
                "Can not send welcome email.",
            })
          );
        }
        setLoading(false);
      });
  };
  return (
    <TableActionButton
      onClick={(e) => {
        e.stopPropagation();
        sendEmail(p.id);
      }}
      loading={loading}
      disabled={!isOK}
      icon={<ForwardToInbox />}
      text="Send invite"
    />
  );
}
