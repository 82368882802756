import { FileUploadOutlined } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Button,
  Card,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { showToast } from "redux/toast/action";
import { t } from "utils/translate";
import { BulkTransferProvider } from "./BulkTransfer";
import * as helpers from "./helpers";
import {
  getFileUploadPreSignedUrl,
  validateBulkDisbursalUploadedCsvFile,
  initiateBulkDisbursals,
  getBulkDisbursalLatestStatus,
} from "utils/apiProvider";
import CSVInfoReport from "./CSVInfoReport";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const ImportCsv = () => {
  const dispatch = useDispatch();

  const contextData = useContext(BulkTransferProvider);
  const [isUploading, setIsUploading] = useState(false);

  const [isValidationInProgress, setIsValidationInProgress] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false); // State for dialog visibility
  const [dialogInfo, setDialoagInfo] = useState({
    title: "",
    description: "",
  });

  useEffect(() => {
    const getStatus = async () => {
      const fileKey = localStorage.getItem("fileKey");
      if (!fileKey) return;

      const result = await getBulkDisbursalLatestStatus(fileKey);
      const status = result.data.job_data.status;
      contextData.setStatus(status);
      if (status === helpers.JobStatus.VALIDATION_STARTED) {
        setIsDialogOpen(true);
        setDialoagInfo({
          title: "CSV Upload In Progress",
          description: "You have an ongoing CSV upload, view its progress.",
        });
      }
      if (status === helpers.JobStatus.DISBUSTAL_RUNNING) {
        setIsDialogOpen(true);
        setDialoagInfo({
          title: "Bulk Transfer In Progress",
          description: "You have an ongoing Bulk Transfer, view its progress.",
        });
      }

      updateJobData(result.data);
    };

    getStatus();
  }, []);

  const updateJobData = (info: any) => {
    contextData.setJobInfo({
      ...contextData.jobInfo,
      jobId: info.job_data.pk,
      status: info.status,
      info: helpers.safeJsonParse(info.job_data.info, []),
      csvResult: info.csv_file_url,
    });
  };

  const handleDialogClose = () => {
    contextData.setStep(2);
    setIsDialogOpen(false);
  };

  const showErrorToast = (msg: string) => {
    dispatch(
      showToast({
        type: "error",
        title: msg,
      })
    );
  };
  const showSuccessToast = (msg: string) => {
    dispatch(
      showToast({
        type: "success",
        title: msg,
      })
    );
  };
  const clearStoreData = () => {
    contextData.setJobInfo({
      jobId: "",
      status: "",
      info: [],
      csvResult: "",
      otpCode: "",
      transactionInfo: {
        totalAmount: 0,
        totalTransactions: 0,
      },
    });
    contextData.setOtpCode("");
    contextData.setStatus("");
  };

  const onDownloadTemplate = () => {
    const link = document.createElement("a");
    link.download = `BulktransferTemplate.csv`;
    link.href = "/files/Bulk-transfer.csv";
    link.click();
  };
  const handleFileChange = async (e: any) => {
    const fileTarget = e.target.files[0];
    if (!validateFile(fileTarget)) {
      e.target.value = "";
      return;
    }
    setIsUploading(true); // Start loader

    try {
      clearStoreData();
      const { presignedUrl, key } = await getPresignedUrlAndKey();
      await uploadFile(presignedUrl, fileTarget);

      showSuccessToast("File uploaded successfully");
      showSuccessToast("File Validation Started");
      await validateUpload(key);
    } catch (error) {
      handleUploadError(error as Error);
    } finally {
      setIsUploading(false); // Stop loader
    }

    e.target.value = "";
  };
  const handleUploadError = (error: Error) => {
    console.error("Upload error:", error);
    dispatch(
      showToast({
        type: "error",
        title: "File upload failed",
        description: error.message,
      })
    );
  };
  const validateFile = (file: File): boolean => {
    if (file.size > 5000000) {
      showErrorToast("Exceed allowed size");
      return false;
    }
    if (file.type !== "text/csv") {
      showErrorToast("Invalid file type");
      return false;
    }
    return true;
  };
  const getPresignedUrlAndKey = async (): Promise<any> => {
    const result = await getFileUploadPreSignedUrl();
    return {
      presignedUrl: result.data.url,
      key: result.data.key,
    };
  };
  const validateUpload = async (file_key: string) => {
    validateBulkDisbursalUploadedCsvFile(file_key);
    localStorage.setItem("fileKey", file_key);
    contextData.setStep(2);
  };

  const uploadFile = async (url: string, file: File) => {
    const response = await fetch(url, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": "text/csv",
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText);
    }
  };
  const initiateTransaction = async () => {
    setIsValidationInProgress(true);
    if (!contextData.jobInfo.jobId) {
      showErrorToast("Job ID is missing. Cannot initiate transaction.");
      return;
    }
    try {
      const result = await initiateBulkDisbursals(contextData.jobInfo.jobId);
      const otpCode = result.data.otp.id;

      contextData.setJobInfo({
        ...contextData.jobInfo,
        otpCode: otpCode,
        transactionInfo: {
          totalAmount: result.data.info.total_amount,
          totalTransactions: result.data.info.total_transactions,
        },
      });

      showSuccessToast("Transaction initiated successfully");
      setIsValidationInProgress(false);
      contextData.setStep(3);
    } catch (error) {
      showErrorToast("Failed to initiate transaction");
    }
  };
  return (
    <>
      <Box>
        <Box>
          <Link to="/accounts" style={{ fontSize: "14px" }}>
            Accounts
          </Link>
          <ArrowForwardIosIcon sx={{ fontSize: 10, marginLeft: 1 }} />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Box sx={{ typography: "h1" }}>{t("bulk_transfer")}</Box>
          <Box>{t("bulk_transfer_info")}</Box>
        </Box>
      </Box>
      <Card
        sx={{
          p: 2,
          width: { xs: "100%", sm: "auto" },
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          gap: 2,
          mt: 5,
        }}
      >
        <Stack direction="column" alignItems="start" spacing={2} useFlexGap>
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 500, fontSize: 16 }}>
              {" "}
              1. {t("fill_out_csv")}
            </Typography>
            <Button
              onClick={onDownloadTemplate}
              sx={{
                border: "1px solid #CCCCDD",
                color: "#111133",
                ml: 3,
                px: 2,
                my: 1,
              }}
              variant="outlined"
            >
              {t("download_template")}
            </Button>
          </Box>
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 500, fontSize: 16 }}>
              {" "}
              2. {t("upload_csv")}
            </Typography>
            <input
              style={{ display: "none" }}
              type="file"
              hidden
              accept=".csv"
              onChange={handleFileChange}
              name="[licenseFile]"
              id="contained-button-file"
            />

            <label htmlFor="contained-button-file">
              <Button
                sx={{
                  border: "1px solid #CCCCDD",
                  color: "#111133",
                  ml: 3,
                  px: 2,
                  my: 1,
                }}
                variant="outlined"
                startIcon={
                  isUploading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <FileUploadOutlined />
                  )
                }
                component="span"
                disabled={isUploading}
              >
                {isUploading ? t("uploading") : t("upload_file")}
              </Button>
            </label>
          </Box>
          <CSVInfoReport
            info={contextData.jobInfo.info}
            csv_file_link={contextData.jobInfo.csvResult}
          />
        </Stack>
      </Card>

      <Box sx={{ mt: 2 }}>
        {contextData.jobInfo.status ===
          helpers.JobStatus.VALIDATION_COMPLETE_SUCESS && (
          <Button
            variant="contained"
            color="primary"
            onClick={initiateTransaction}
            startIcon={
              isValidationInProgress ? (
                <CircularProgress size={20} />
              ) : (
                <ArrowForwardIosIcon />
              )
            }
          >
            Initiate Transaction
          </Button>
        )}
      </Box>

      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle fontFamily={"Poppins"} fontSize={28}>
          {dialogInfo.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText fontFamily={"Poppins"} fontSize={16}>
            {dialogInfo.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            View
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImportCsv;
