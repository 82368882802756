export interface IReduxBaseAction {
  type: string;
  payload?: any;
  meta: {
    success?: string;
    failure?: string;
    cancel?: string;
    redirect?: string;
  };
  error?: string;
}

export interface IReduxToastState {
  type: "success" | "error" | null;
  description?: any;
  title: string;
}

export interface IReduxAuthState {
  pending: boolean;
  error: null | boolean;
  user: IReduxUserState | null;
}

export interface IReduxState {
  auth: IReduxAuthState;
  toast: IReduxToastState;
}

export enum IRoles {
  SUPERADMIN = "superadmin",
  ADMIN = "admin",
}

export interface IReduxUserState {
  Email: string;
  ID: string;
  Role: "superadmin" | "admin";
  expires_in: 3599;
}
