import { FormControl, FormLabel, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { IWalletHolderProps } from "interfaces/wallet-holder";
import { useEffect, useState } from "react";
import { getIdTypes } from "utils/apiProvider";
import { capitalizeWord } from "utils/capitalizeWord";
import { t } from "utils/translate";
import { renderWalletStatus } from "./Columns";
import { formatPhoneNumber } from "utils/formatPhoneNumber";
import { UTCLocalTimeStamp } from "components/shared/UTCLocalTimestampComponent";

const UserProfile = ({
  data,
  getCountry,
}: {
  data: IWalletHolderProps;
  getCountry: (iso_alpha2: string) => string;
}) => {
  const [type, setType] = useState("");

  useEffect(() => {
    getIdTypes().then((res) => {
      const idType = res.find(
        (item: any) => item.code === data.identification_type
      )?.description.display_name;
      if (idType) {
        setType(idType);
      }
    });
  }, [data.identification_type]);
  return (
    <Stack gap="24px">
      <Typography variant="h2">{t("personal_information")}</Typography>
      <FormControl>
        <FormLabel>{t("title")}</FormLabel>
        {data?.title}
      </FormControl>
      <FormControl>
        <FormLabel>{t("first_name")}</FormLabel>
        {data?.first_name}
      </FormControl>
      <FormControl>
        <FormLabel>{t("surname")}</FormLabel>
        {data.last_name}
      </FormControl>
      <FormControl>
        <FormLabel>{t("preferred_name")}</FormLabel>
        {data?.preferred_name}
      </FormControl>
      {data?.gender && (
        <FormControl>
          <FormLabel>{t("gender")}</FormLabel>
          {capitalizeWord(data?.gender)}
        </FormControl>
      )}
      <FormControl>
        <FormLabel>{t("nationality")}</FormLabel>
        {data.nationality}
      </FormControl>
      <FormControl>
        <FormLabel>{t("date_of_birth")}</FormLabel>
        {dayjs(data.date_of_birth).format("DD/MM/YYYY")}
      </FormControl>
      <FormControl>
        <FormLabel>{t("identification_type")}</FormLabel>
        {type ?? data.identification_type}
      </FormControl>
      <FormControl>
        <FormLabel>{t("identification_no")}</FormLabel>
        {data.identification_no}
      </FormControl>
      <FormControl>
        <FormLabel>{t("country_of_issue")}</FormLabel>
        {getCountry(data.country_of_issue)}
      </FormControl>
      <Typography variant="h2">{t("contact_info")}</Typography>
      <FormControl>
        <FormLabel>{t("email")}</FormLabel>
        {data.email}
      </FormControl>
      <FormControl>
        <FormLabel>{t("phone")}</FormLabel>
        {formatPhoneNumber(data.phone_code, data.phone, true)}
      </FormControl>
      <Typography variant="h2">{t("address")}</Typography>
      <FormControl>
        <FormLabel>{t("billing_delivery_address1")}</FormLabel>
        {data.billing_delivery_address1}
      </FormControl>
      <FormControl>
        <FormLabel>{t("billing_delivery_address2")}</FormLabel>
        {data.billing_delivery_address2 ?? "-"}
      </FormControl>
      <FormControl>
        <FormLabel>{t("billing_delivery_city")}</FormLabel>
        {data.billing_delivery_city}
      </FormControl>
      <FormControl>
        <FormLabel>{t("billing_delivery_state")}</FormLabel>
        {data.billing_delivery_state}
      </FormControl>
      <FormControl>
        <FormLabel>{t("billing_delivery_zip_code")}</FormLabel>
        {data.billing_delivery_zip_code}
      </FormControl>
      <FormControl>
        <FormLabel>{t("billing_delivery_country")}</FormLabel>
        {getCountry(data.billing_delivery_country)}
      </FormControl>
      <Typography variant="h2">{t("account_information")}</Typography>
      <FormControl>
        <FormLabel>{t("created_at")}</FormLabel>
        <UTCLocalTimeStamp date={data.created_at} />
      </FormControl>
      <FormControl>
        <FormLabel>{t("last_status_change_at")}</FormLabel>
        <UTCLocalTimeStamp date={data.last_status_change_at} />
      </FormControl>
      <FormControl>
        <FormLabel>{t("wallet_status")}</FormLabel>
        {renderWalletStatus(data)?.name ?? "-"}
      </FormControl>
      <FormControl>
        <FormLabel>{t("last_login")}</FormLabel>
        <UTCLocalTimeStamp date={data.last_login} />
      </FormControl>
      <FormControl>
        <FormLabel>Database ID</FormLabel>
        {data.id ?? "-"}
      </FormControl>
      <FormControl>
        <FormLabel>MatchMove ID</FormLabel>
        {data.customer_hash_id ?? "-"}
      </FormControl>
    </Stack>
  );
};

export default UserProfile;
