import BulkAddWalletHolder from "components/users/BulkAddWalletHolder";

import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { t } from "utils/translate";

const UserMenuAction = ({
  setReload,
}: {
  setReload: (reload: boolean) => void;
}) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Button
        variant="contained"
        onClick={() => navigate("/wallet-holders/add")}
        sx={{ fontSize: 14 }}
      >
        {t("add_user_button_label")}
      </Button>
      <Box sx={{ display: "none" }}>
        <BulkAddWalletHolder setReload={setReload} />
        <Link
          href="/files/Bulk-add-users.csv"
          download="Bulk-add-users.csv"
          target="_blank"
          sx={{
            marginLeft: "15px",
            display: "inline-block",
            color: "#5D31FF",
            fontSize: "14px",
            fontWeight: 500,
            textDecoration: "underline",
            "&:hover": {
              color: "#381C9F",
              textDecoration: "underline",
            },
          }}
        >
          Download template
        </Link>
      </Box>
    </Box>
  );
};

export default UserMenuAction;
