import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ErrorScreen from "components/ErrorScreen";
import { IReduxState } from "interfaces/redux";
import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { userCheck } from "redux/auth/action";
import getAvailableRoutes from "router";
import { theme } from "themes";
import AnonTemplate from "themes/AnonTemplate";
import AuthTemplate from "themes/AuthTemplate";

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return <></>;
};

const App: React.FC = () => {
  const { auth } = useSelector((state: IReduxState) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth?.user) {
      dispatch(userCheck());
    }
  }, [auth, dispatch]);

  const getLayout = (template?: FC) => {
    if (template) {
      return template;
    }

    if (auth.user) {
      return AuthTemplate;
    }
    return AnonTemplate;
  };

  const renderRouter = useCallback(() => {
    const routes = getAvailableRoutes(auth?.user);
    return routes.map((item) => {
      const PageComponent = item.pageComponent;
      const Layout = getLayout(item.template);
      return (
        <Route
          path={item.path}
          element={
            <Layout title={item.pageTitle}>
              <ScrollToTop />
              <PageComponent />
            </Layout>
          }
          key={item.path}
        />
      );
    });
  }, [auth?.user]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <Router>
          <Routes>
            <Route path="*" element={<ErrorScreen />} />
            {renderRouter()}
          </Routes>
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
