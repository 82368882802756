/* eslint-disable react/no-unstable-nested-components */
import { Box, Input, InputLabel, Modal, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { CustomError } from "interfaces/error";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toast/action";
import { style } from "themes/modal";
import { importWalletHolder } from "utils/apiProvider";
import { t } from "utils/translate";

const BulkAddWalletHolder = ({
  setReload,
}: {
  setReload: (reload: boolean) => void;
}) => {
  const dispatch = useDispatch();

  const [uploading, setUploading] = useState(false);
  const handleChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      if (file.size > 5000000) {
        dispatch(
          showToast({
            type: "error",
            title: "Exceed allowed size",
          })
        );
      } else if (file.type !== "text/csv") {
        dispatch(
          showToast({
            type: "error",
            title: "Invalid file type",
          })
        );
      } else {
        setUploading(true);
        formData.append("file", file);
        importWalletHolder(formData)
          .then((res) => {
            setUploading(false);

            if (res.data.success > 0) {
              setReload(true);
            }
            if (res.data.success === res.data.total) {
              dispatch(
                showToast({
                  type: "success",
                  title: "Import data successfully!",
                })
              );
            } else {
              dispatch(
                showToast({
                  type: "error",
                  title: `${res.data.success} of ${res.data.total} users have been successfully created.`,
                  description: () => (
                    <a
                      href={res.data.csv_download_url}
                      download="Bulk Import Error.csv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download error reports
                    </a>
                  ),
                })
              );
            }
          })
          .catch((err: AxiosError<CustomError>) => {
            setUploading(false);

            if (err.response?.status !== 401) {
              dispatch(
                showToast({
                  type: "error",
                  title:
                    err.response?.data.error.message ?? "Can not import data.",
                })
              );
            }
          });
      }
    }
    e.target.value = "";
  };
  return (
    <>
      {uploading && (
        <Modal open={uploading}>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Uploading...
            </Typography>
          </Box>
        </Modal>
      )}
      <InputLabel
        sx={{
          border: "1px solid #BEADFF",
          color: "#381C9F",
          borderRadius: "100px",
          fontSize: "14px",
          padding: "10px 20px",
          textAlign: "center",
          display: "inline-block",
          cursor: "pointer",
          "&:hover": {
            background: "rgba(93, 49, 255, 0.04)",
          },
        }}
        htmlFor="upload-photo"
      >
        {t("bulk_add_users_button_label")}
      </InputLabel>
      <Input
        sx={{ display: "none" }}
        id="upload-photo"
        type="file"
        onChange={handleChange}
      />
    </>
  );
};

export default BulkAddWalletHolder;
