import { AxiosRequestConfig } from "axios";
import { ITransactionProps } from "interfaces/transaction";
import customAxios from "utils/request";
import { getStorage, handleStorage } from "./handleStorage";
import { Card } from "interfaces/wallet-holder";

export const countriesKey = `${process.env.REACT_APP_NAMESPACE}.countries`;
export const phonecodesKey = `${process.env.REACT_APP_NAMESPACE}.phonecodes`;
export const nationalitiesKey = `${process.env.REACT_APP_NAMESPACE}.nationalities`;

export interface IFilterProps {
  limit: number;
  cursor?: number | null;
  sort?:
    | {
        [x: string]: "desc" | "asc";
      }[]
    | null;
  order?: "desc" | "asc";
  mode?: "or" | "and";
  filter?: Record<string, string> | undefined;
}

export const getNationalities = async () => {
  const nationalities: any[] = await getStorage(nationalitiesKey);

  if (nationalities) {
    return nationalities;
  }

  const params: AxiosRequestConfig = {
    method: "GET",
    url: `/wallet/v2/config/kyc/nationalities`,
    headers: {
      accept: "*/*",
    },
  };
  const response = await customAxios.request({ ...params });
  handleStorage(nationalitiesKey, response.data.data);
  return response.data.data;
};

export const getIdTypes = async () => {
  const params: AxiosRequestConfig = {
    method: "GET",
    url: `/wallet/v2/config/kyc/identification-types`,
    headers: {
      accept: "*/*",
    },
    params: {
      entity_type: "individual",
    },
  };
  const response = await customAxios.request({ ...params });

  return response.data.data;
};

export const getCountries = async () => {
  const countries: any[] = await getStorage(countriesKey);

  if (countries) {
    return countries;
  }

  const params: AxiosRequestConfig = {
    method: "GET",
    url: `/wallet/v2/config/kyc/countries`,
    headers: {
      accept: "*/*",
    },
  };
  const response = await customAxios.request({ ...params });
  handleStorage(countriesKey, response.data.data);
  return response.data.data;
};

export const getPhoneCodes = async () => {
  const phoneCodes: any[] = await getStorage(phonecodesKey);

  if (phoneCodes) {
    return phoneCodes;
  }

  const params: AxiosRequestConfig = {
    method: "GET",
    url: `/wallet/v2/config/kyc/phone-country-codes`,
    headers: {
      accept: "*/*",
    },
  };
  const response = await customAxios.request({ ...params });
  handleStorage(phonecodesKey, response.data.data);
  return response.data.data;
};

interface IWalletHoldersResponse {
  data: any;
  next_cursor: number;
  limit: number;
  total_count: number;
}

export const getWalletHolders = async (
  filter: IFilterProps
): Promise<IWalletHoldersResponse> => {
  const f: any = {
    cursor: filter.cursor,
    limit: filter.limit,
    type: "wallet-holder",
  };
  if (filter.sort) {
    f.sort = JSON.stringify(filter.sort);
  }
  f.mode = filter.mode ?? "or";
  if (filter.filter !== undefined && Object.entries(filter.filter).length > 0)
    f.filter = JSON.stringify(filter.filter);
  const params: AxiosRequestConfig = {
    method: "GET",
    url: `/user/v2/users`,
    headers: {
      accept: "*/*",
    },
    params: f,
  };
  const response = await customAxios.request<any, IWalletHoldersResponse>({
    ...params,
  });
  return response.data;
};

export const getWalletHolderDetail = async (id: string) => {
  const params: AxiosRequestConfig = {
    method: "GET",
    url: `/user/v2/users/${id}`,
    headers: {
      accept: "*/*",
    },
    params: {
      id,
    },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const updateUser = async (data: object, id: string) => {
  const params: AxiosRequestConfig = {
    method: "PATCH",
    url: `/user/v2/users/${id}`,
    headers: {
      accept: "*/*",
    },
    data,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const addUser = async (data: object) => {
  const params: AxiosRequestConfig = {
    method: "POST",
    url: `/user/v2/users`,
    headers: {
      accept: "*/*",
    },
    data,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const archiveUser = async (id: string, archive: boolean) => {
  const params: AxiosRequestConfig = {
    method: "POST",
    url: `/user/v2/users/${id}/archive`,
    headers: {
      accept: "*/*",
    },
    data: {
      is_archive: archive,
    },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const p2pRequest = async (data: ITransactionProps) => {
  const params: AxiosRequestConfig = {
    method: "POST",
    url: `/wallet/v2/transactions/p2w-initiate`,
    headers: {
      accept: "*/*",
    },
    data: {
      ...data,
      amount: data.amount,
    },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};
export const p2pConfirm = async (data: {
  transaction_id: string;
  otp_code: string;
}) => {
  const params: AxiosRequestConfig = {
    method: "POST",
    url: `/wallet/v2/transactions/${data.transaction_id}/p2w-confirm`,
    headers: {
      accept: "*/*",
    },
    data,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const getAccounts = async (userId: string) => {
  const params: AxiosRequestConfig = {
    method: "GET",
    url: `/wallet/v2/wallets/${userId}/accounts`,
    headers: {
      accept: "*/*",
    },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const resendOtp = async (transactionId: string, isBulk?: boolean) => {
  const url = isBulk
    ? // Bulk transfer api doesn't exist. To be cleaned up. - Pooja
      "/admin/transfers/bulk-transfer-funds-resend-otp"
    : `/wallet/v2/transactions/p2w/${transactionId}/otp-resend`;
  const data = isBulk
    ? {
        transferFundsId: transactionId,
      }
    : {
        transaction_id: transactionId,
      };
  const params: AxiosRequestConfig = {
    method: "POST",
    url,
    headers: {
      accept: "*/*",
    },
    data,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export interface IFilterAccountProps {
  transaction_currency: string;
}

export const getAccountDetail = async (currency: string, userId: string) => {
  const params: AxiosRequestConfig = {
    method: "GET",
    url: `/wallet/v2/wallets/${userId}/accounts/${currency}`,
    headers: {
      accept: "*/*",
    },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const getTransactionByCurrency = async (
  currency: string,
  filter: IFilterProps,
  userId: string
) => {
  const f: any = { ...filter };
  if ((filter.sort || []).length > 0) {
    f.sort = JSON.stringify(filter.sort);
  }
  const params: AxiosRequestConfig = {
    method: "GET",
    url: `/wallet/v2/wallets/${userId}/transactions`,
    headers: {
      accept: "*/*",
    },
    params: {
      ...f,
      // transaction_currency: currency
    },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const getTransactionDetail = async (txnId: string, userId: string) => {
  const params: AxiosRequestConfig = {
    method: "GET",
    url: `/wallet/v2/wallets/${userId}/transactions/${txnId}`,
    headers: {
      accept: "*/*",
    },
  };
  const response = await customAxios.request({ ...params });
  return response;
};

// This API doesn't exist. To be cleaned up. - Pooja
export const verifyUser = async (id: number, _email: string) => {
  const params: AxiosRequestConfig = {
    method: "POST",
    url: `/admin/wallet-holders/verify/${id}`,
    headers: {
      accept: "*/*",
    },
    data: {
      cancel_url: `${process.env.REACT_APP_VERIFIED_REDIRECT}wallet-holders/verified-cancel`,
      complete_url: `${process.env.REACT_APP_VERIFIED_REDIRECT}wallet-holders/show/${id}`,
    },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const getWalletHolderCard = async (id: string) => {
  const params: AxiosRequestConfig = {
    method: "GET",
    url: `/wallet/v2/wallets/${id}/cards`,
    headers: {
      accept: "*/*",
    },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const sendWelcomeEmail = async (id: string) => {
  const params: AxiosRequestConfig = {
    method: "POST",
    url: `/user/v2/users/${id}/activation/send-invite`,
    headers: {
      accept: "*/*",
    },
    data: {
      userId: id,
    },
  };
  const response = await customAxios.request({ ...params });
  return response;
};

export const sendAccountsEmail = async (id: string) => {
  const params: AxiosRequestConfig = {
    method: "POST",
    url: `/user/v2/users/${id}/send-wallet-details`,
    headers: {
      accept: "*/*",
    },
    data: {
      id,
    },
  };
  return await customAxios.request({ ...params });
};

export const sendCard = async (id: string) => {
  const params: AxiosRequestConfig = {
    method: "POST",
    url: `/wallet/v2/wallets/${id}/cards/mark-as-sent`,
    headers: {
      accept: "*/*",
    },
  };
  const response = await customAxios.request({ ...params });
  return response;
};

// This API doesn't exist. To be cleaned up. - Pooja
export const verifyEmail = async (token: string) => {
  const params = {
    method: "PATCH",
    url: `/admin/auth/update-wallet-holder-email`,
    headers: {
      accept: "*/*",
    },
    data: {
      token,
    },
  };
  const response = await customAxios.request({ ...params });
  return response;
};

export const importWalletHolder = async (formData: any) => {
  const params = {
    method: "POST",
    url: `/user/v2/users/bulk-import`,
    headers: {
      accept: "*/*",
    },
    data: formData,
  };
  const response = await customAxios.request({ ...params });
  return response;
};

// Bulk transfer api doesn't exist. To be cleaned up. - Pooja
export const requestBulkTransfer = async (formData: FormData) => {
  const params = {
    method: "POST",
    url: `/admin/transfers/bulk-transfer-funds-request`,
    headers: {
      accept: "*/*",
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

// Bulk transfer api doesn't exist. To be cleaned up. - Pooja
export const completeTransactionBulkTransfer = async (
  otpCode: string,
  transactionId: string
) => {
  const params: AxiosRequestConfig = {
    method: "POST",
    url: `/admin/transfers/bulk-transfer-funds-confirm`,
    headers: {
      accept: "*/*",
    },
    data: {
      otpCode,
      batchId: transactionId,
    },
  };
  const response = await customAxios.request({ ...params });
  return response;
};

export const exportWalletHolders = async () => {
  const params: AxiosRequestConfig = {
    method: "GET",
    url: `/user/v2/users/bulk-export`,
    headers: {
      accept: "*/*",
    },
  };
  const response = await customAxios.request({ ...params });
  return response;
};

export const archiveCard = async (id: string, userId: string) => {
  const params: AxiosRequestConfig = {
    method: "PATCH",
    url: `/wallet/v2/wallets/${userId}/cards/${id}/block`,
    headers: {
      accept: "*/*",
    },
    data: {
      reason: "lost",
    },
  };
  const response = await customAxios.request({ ...params });
  return response;
};

export const createPhysicalCard = async (userId: string, proxyNo: string) => {
  const params: AxiosRequestConfig = {
    method: "POST",
    url: `/wallet/v2/wallets/${userId}/card-assign`,
    headers: {
      accept: "*/*",
    },
    data: {
      userId,
      proxyNo,
    },
  };
  const response = await customAxios.request({ ...params });
  return response;
};

export const requestNewPhysicalCard = async (userId: string) => {
  const params: AxiosRequestConfig = {
    method: "POST",
    url: `/wallet/v2/wallets/${userId}/card-request`,
    headers: {
      accept: "*/*",
    },
    data: {
      userId,
    },
  };
  const response = await customAxios.request({ ...params });
  return response;
};

/**
 * Gets all cards for user `id`
 */
export const getAllCardsForUser = async (
  id: string,
  type: "physical" | "virtual" | "all"
): Promise<Card[]> => {
  const params: AxiosRequestConfig = {
    method: "GET",
    url: `/wallet/v2/wallets/${id}/cards`,
    // url: `/app/cards?user_id=${id}&always_show_card_number=true`,
    headers: {
      accept: "*/*",
    },
  };
  const response = await customAxios.request({ ...params });
  return response.data.filter((card: any) =>
    type === "all"
      ? true
      : type === "physical"
      ? card.type === "physical"
      : card.type === "virtual"
  );
};

export const getFileUploadPreSignedUrl = async () => {
  const params: AxiosRequestConfig = {
    method: "GET",
    url: `/wallet/v2/disbursals/setup/upload-url/csv`,
    headers: {
      accept: "*/*",
    },
  };
  const response = await customAxios.request({ ...params });
  return response;
};

//try catch
export const validateBulkDisbursalUploadedCsvFile = async (key: string) => {
  try {
    const params: AxiosRequestConfig = {
      method: "POST",
      url: `/wallet/v2/disbursals/setup/validate/csv`,
      headers: {
        accept: "*/*",
      },
      data: {
        key: key,
      },
    };
    customAxios.request({ ...params });
  } catch (error) {
    console.error(error);
  }
};

export const initiateBulkDisbursals = async (jobId: string) => {
  const params: AxiosRequestConfig = {
    method: "POST",
    url: `/wallet/v2/disbursals/${jobId}/initiate`,
    headers: {
      accept: "*/*",
    },
  };
  const response = await customAxios.request({ ...params });
  return response;
};

export const confirmBulkDisbursals = async (body: any) => {
  const params: AxiosRequestConfig = {
    method: "POST",
    url: `/wallet/v2/disbursals/confirm`,
    headers: {
      accept: "*/*",
    },
    data: body,
  };
  const response = await customAxios.request({ ...params });
  return response;
};

export const getBulkDisbursalLatestStatus = async (fileKey: string) => {
  const params: AxiosRequestConfig = {
    method: "GET",
    url: `/wallet/v2/disbursals/getJobByfileKey?fileKey=${fileKey}`,
    headers: {
      accept: "*/*",
    },
  };
  const response = await customAxios.request({ ...params });
  return response;
};

export const bulkJobResendOtp = async (body: any) => {
  const params: AxiosRequestConfig = {
    method: "POST",
    url: `/wallet/v2/disbursals/resendOtp`,
    headers: {
      accept: "*/*",
    },
    data: body,
  };
  const response = await customAxios.request({ ...params });
  return response;
};
