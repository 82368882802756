import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const GradientIcon = ({ icon, sx }: { icon: string; sx?: any }) => {
  const getIcon = () => {
    switch (icon) {
      case "RemoveCircleOutlineIcon":
        return (
          <RemoveCircleOutlineIcon sx={{ fill: "url(#linearColors)", ...sx }} />
        );
      case "InfoOutlinedIcon":
        return <InfoOutlinedIcon sx={{ fill: "url(#linearColors)", ...sx }} />;
      default:
    }
  };
  return (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#5533FF" />
          <stop offset={1} stopColor="#00AADD" />
        </linearGradient>
      </svg>
      {getIcon()}
    </>
  );
};

export default GradientIcon;
